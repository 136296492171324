import React, { useCallback, useEffect, useState } from "react";
import { login, signUp } from "../ds/auth";
import {
  addFileToFavourite,
  addFolderToFavourite,
  deleteUser,
  getAllUsers,
  getUser,
  getUserProfile,
  updateUser,
} from "../ds/user";
import { useDispatch } from "react-redux";
import { setUserData } from "../feature/auth";
// import { signUp } from "aws-amplify/auth";

const useUsers = ({
  role = "",
  page,
  limit,
  search = "",
  id,
  startDate,
  endDate,
}: {
  role?: string;
  page?: number;
  limit?: number;
  search?: string;
  id?: string | undefined;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
}) => {
  const [users, setUsers] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getUsers = useCallback(async () => {
    setLoading(true);
    const response = await getAllUsers({
      role: role,
      page: page,
      limit: limit,
      search: search,
      startDate: startDate,
      endDate: endDate,
    });
    setLoading(false);
    setUsers(response?.data);
  }, [endDate, limit, page, role, search, startDate]);

  const getUserDetail = useCallback(async () => {
    if (id) {
      const response = await getUser(id);
      setUser(response);
    }
  }, [id]);
  const deleteUserDetail = useCallback(async (userId: string) => {
    if (userId) {
      const response = await deleteUser(userId);
      setUser(response);
    }
  }, []);

  useEffect(() => {
    getUsers();
    getUserDetail();
  }, [getUserDetail, getUsers]);

  const updateUserDetail = async (id: string, data: any) => {
    const response = await updateUser(id, data);
    return response;
  };
  const addfavouriteFolder = async (data: any) => {
    const response = await addFolderToFavourite(data);
    const userProfiledata: any = await getUserProfileDetail();
    console.log({ userProfiledata });
    dispatch(setUserData(userProfiledata?.data));

    return response;
  };
  const addfavouriteFile = async (data: any) => {
    const response = await addFileToFavourite(data);
    return response;
  };
  const getUserProfileDetail = async () => {
    const response = await getUserProfile();
    return response;
  };

  return {
    users,
    loading,
    getUsers,
    updateUserDetail,
    getUserProfileDetail,
    deleteUserDetail,
    addfavouriteFolder,
    addfavouriteFile,
    user,
  };
};

export default useUsers;
