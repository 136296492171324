import React, { useState } from "react";
import Sidebar from "../sidebar";
import { PiUserSquareLight, PiUsers } from "react-icons/pi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { BsFileBarGraph } from "react-icons/bs";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { GoGear } from "react-icons/go";
import { BsBoxArrowRight } from "react-icons/bs";
import { BsArchive } from "react-icons/bs";
import { GoTag } from "react-icons/go";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const navs = [
  {
    id: 1,
    route: "/admin/dashboard",
    name: "Dashboard",
    Icon: BsFileBarGraph,
    showArrow: false,
  },
  {
    id: 2,
    route: "/admin/user-management",
    name: "User Management",
    Icon: PiUsers,
    showArrow: false,
  },
  {
    id: 3,
    route: "/admin/admin-management",
    name: "Admin Management",
    Icon: PiUserSquareLight,
    showArrow: false,
  },
];

const AdminNavs = navs.filter((data) => data.name !== "Admin Management");
const AdminLayout = () => {
  const [showmobileNav, setShowmobileNav] = useState(false);

  const [closeNav, setCloseNav] = useState(false);
  //   const navigate = useNavigate();
  // const { closeNav, setCloseNav } = useNav();
  const { user }: any = useSelector((state: any) => state.auth);
  return (
    <div className="flex relative">
      <aside className={`w-[280px] border-r border-gray-300 h-screen fixed `}>
        <Sidebar
          setCloseNav={setCloseNav}
          closeNav={closeNav}
          navs={user?.role === "ADMIN" ? AdminNavs : navs}
          setShowMobileNav={setShowmobileNav}
        />
      </aside>
      <main
        className={`w-full pl-[280px]
            `}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
