import moment from "moment";

export const DateFilterFunc = (data: string) => {
  switch (data) {
    case "THIRTY_DAYS":
      return moment().subtract(30, "d").format("YYYY-MM-DD") || null;
    case "SEVEN_DAYS":
      return moment().subtract(7, "d").format("YYYY-MM-DD") || null;
    case "TODAY":
      return moment().format("YYYY-MM-DD") || null;
    default:
      break;
  }
};

export const userRole = (role: string) => {
  switch (role) {
    case "SUPER_ADMIN":
      return "super admin";
    case "ADMIN":
      return "admin";
    case "OWNER":
      return "home owner";
    case "BUILDER":
      return "builder";
    default:
      return "";
  }
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
