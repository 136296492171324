import React from "react";
import ProjectCard from "../../../components/shared/cards/ProjectCard";
import { useNavigate, useParams } from "react-router-dom";
import { TiFolder } from "react-icons/ti";
import { MdOutlineChevronRight } from "react-icons/md";
import useFolders from "../../../hooks/useFolders";
import moment from "moment";
import FolderCard from "../../../components/shared/cards/FolderCard";
import useUsers from "../../../hooks/useUsers";
import { formatBytes } from "../../../utils";

const AdminFolderDetail = () => {
  const { id } = useParams();
  const { folders, totalMedia } = useFolders({ owner: id });
  const { user: owner } = useUsers({ id });
  // const { user } = useUsers(folder?.owner?._id);
  const navigate = useNavigate();
  console.log({ owner });
  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[12px] w-full py-[24px] px-[16px] lg:px-[24px] lg:py-[48px]">
        <div>
          <div className="flex items-center gap-[8px]">
            <TiFolder className="w-[28px] h-[28px]" />
            <MdOutlineChevronRight className="w-[16px] h-[16px]" />
            <h1 className="text-[14px] font-semibold">
              {owner?.data?.fullName}
            </h1>
          </div>
        </div>
        <h1 className="text-[30px] font-semibold">{owner?.data?.fullName}</h1>
        <div className="flex flex-col gap-y-[12px]">
          <p className="underline text-[#2E90FA]">{owner?.data?.address}</p>
          <p>
            Created {moment(owner?.data?.createdAt).format("MMMM DD, YYYY")}
          </p>
          <p>{formatBytes(totalMedia[0]?.count)}</p>
        </div>
        <div className="border-t border-gray-300">
          <div className="pt-[24px]"></div>
        </div>
      </div>
      <div className="flex flex-col items-center px-[24px] lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full">
        {folders?.map((data: any, index: number) => {
          // const
          return (
            <FolderCard
              data={{
                name: data?.folderName,
                address: data?.owner?.address,
                files: data?.files,
                createdAt: data?.createdAt,
              }}
              key={data?._id}
              handleClick={() => navigate(`/admin/folders/${data?._id}/detail`)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AdminFolderDetail;
