import { Formik, FormikHelpers, FormikValues } from "formik";
import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import useUsers from "../../hooks/useUsers";
import toast from "react-hot-toast";
import InputText from "../shared/input/InputText";
import { useDispatch } from "react-redux";
import { setUserData } from "../../feature/auth";

const TermsOfServiceModal = ({
  closeModal,
  requestType,
  getAllData,
  userData,
  setSelectedStateId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const { updateUserDetail, getUserProfileDetail } = useUsers({});
  const dispatch = useDispatch();
  //   console.log({ user });
  const initialValues = {
    acceptedTos: "",
  };

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const payload = {
        acceptedTos: data?.acceptedTos,
      };
      const res = await updateUserDetail(userData._id, {
        ...payload,
      });
      const userProfiledata: any = await getUserProfileDetail();
      console.log({ userProfiledata });
      dispatch(setUserData(userProfiledata?.data));

      if (res.code === "00") {
        toast.success("Terms of service updated");
        closeModal();
      }
      if (res.code !== "00") {
        setLoading(false);
        toast.error(res.message || "Error updating Terms of service");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
      if (getAllData) {
        setTimeout(() => {
          getAllData();
        }, 1000);
      }
    }
  };

  return (
    <div className="px-[64px] py-[96px] flex flex-col gap-[20px] bg-white h-[500px]  overflow-y-auto rounded-[8px]">
      <div className="flex justify-end w-full">
        <div className="">
          <IoCloseOutline
            style={{ width: 26, height: 26, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
      </div>
      <div className="flex flex-col gap-[32px]">
        <p className="text-center text-[18px]">Current as of 11/28/2024</p>
        <h1 className="text-center text-[48px] font-semibold">
          Constructgraphy Terms of Service
        </h1>
      </div>
      <div className="px-[32px] flex flex-col gap-[64px]">
        <div className="flex flex-col gap-[20px]">
          <h1 className="text-[30px] font-medium">Introduction:</h1>
          <p>
            Constructgraphy (“we”, “us”, or “our”;) provides construction
            photography services and operates the website
            [www.constructgraphy.com] “Site”. These Terms of Service “Terms”
            govern your access to and use of the Site, services, and content.
          </p>
        </div>
      </div>
      <div className="px-[32px] flex flex-col gap-[64px]">
        <div className="flex flex-col gap-[20px]">
          <h1 className="text-[30px] font-medium">Definitions:</h1>
          <p>
            “Client” means any individual or entity that engages our services.
          </p>
          <p>“Content” includes images, videos, and other media.</p>
          <p>
            “Services” includes construction photography, aerial photography,
            time-lapse photography, and related services.
          </p>
          <p>“Site” refers to our website and all its features.</p>
          <p>“User” means any individual accessing the Site.</p>
        </div>
      </div>
      <div className="px-[32px] flex flex-col gap-[64px]">
        <div className="flex flex-col gap-[20px]">
          <h1 className="text-[30px] font-medium">Terms of Use</h1>
          <p>
            1. Acceptance: By using the Site or Services, you agree to these
            Terms.
          </p>
          <p>
            2. Age Restriction: You must be at least 18 years old to use our
            Services.
          </p>
          <p>
            3. Intellectual Property: Constructgraphy retains ownership of all
            Content.
          </p>
          <p>
            4. License Grant: Clients receive a non-exclusive license to use
            Content for agreed-upon purposes.
          </p>
          <p>5. Prohibited Use: You shall not:</p>
          <p>- Use Content for unauthorised purposes.</p>
          <p>- Resell or distribute Content.</p>
          <p>- Remove watermarks or copyright notices.</p>
          <p>
            6. Payment Terms: Clients shall pay fees as outlined in our
            agreement.
          </p>
          <p>7. Cancellation Policy: [Insert cancellation policy].</p>
          <p>8. Warranty Disclaimer: We disclaim warranties of any kind.</p>
          <p>
            9. Limitation of Liability: Our liability is limited to [insert
            amount].
          </p>
          <p>
            10. Indemnification: You agree to indemnify us against claims,
            damages, and expenses.
          </p>
        </div>
      </div>
      <div className="px-[32px] flex flex-col gap-[64px]">
        <div className="flex flex-col gap-[20px]">
          <h1 className="text-[30px] font-medium">Can I opt-in and opt-out?</h1>
          <p>1. Provide accurate project information.</p>
          <p>2. Ensure site safety and accessibility.</p>
          <p>3. Obtain necessary permits and approvals.</p>
          <p>Constructgraphy Responsibilities:</p>
          <p>1. Deliver high-quality Services.</p>
          <p>2. Maintain confidentiality.</p>
          <p>3. Comply with applicable laws.</p>
          <p>
            Governing Law: These Terms shall be governed by and construed in
            accordance with
          </p>
          <p>the laws of Alberta and Canada.</p>
          <p>
            Dispute Resolution: Any disputes shall be resolved through [insert
            dispute resolution process]
          </p>
          <p>
            Changes to Terms: We reserve the right to modify these Terms at any
            time.
          </p>
          <p>
            Contact Us: For questions or concerns, please contact:
            help@constructgraphy.com
          </p>
          <p>
            Acceptance: By using the Site or Services, you acknowledge that you
            have read, understood, and agree to these Terms.
          </p>
        </div>
      </div>
      <div className="px-[32px] mt-[20px]">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, errors }) => {
            console.log({ values });
            return (
              <>
                <div className="w-max ">
                  <InputText
                    placeholder={""}
                    label={"I agreee to the terms of service"}
                    handleChange={handleChange}
                    rowFormat={true}
                    value={values.acceptedTos}
                    name={"acceptedTos"}
                    type={"checkbox"}
                    inputClassName="h-max"
                    textStyle="flex flex-row-reverse items-center gap-[8px]"
                    error={undefined}
                    setViewPassword={function (value: any): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </div>
                <div className="mt-[20px]">
                  <button
                    type="submit"
                    disabled={loading ? true : false}
                    onClick={() => handleSubmit()}
                    className="bg-[#A74225] rounded-[8px] text-white flex justify-center items-center h-[40px] w-full gap-2"
                  >
                    {loading ? "Creating..." : "continue"}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default TermsOfServiceModal;
