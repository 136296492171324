import React, { useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminDashGuard = ({ children }: { children: React.ReactElement }) => {
  const { islogin, user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (!islogin && !accessToken) {
      navigate("/admin/login");
      //   switch (user?.role) {
      //     case "SUPER_ADMIN":
      //       console.log("here...");
      //       break;
      //     case "BUILDER":
      //       console.log("here...");
      //       navigate("/dashboard/builder");
      //       break;
      //     case "OWNER":
      //       console.log("here...");
      //       navigate("/dashboard");
      //       break;
      //     default:
      //     //do nothing
      //   }
    }
  }, [accessToken, islogin, navigate, user]);

  return <>{children}</>;
};

export default AdminDashGuard;
