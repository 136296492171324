import React from "react";
import AuthLayout from "../../components/shared/layouts/AuthLayout";
import MainLayout from "../../components/shared/layouts/MainLayout";

const CookiePolicy = () => {
  return (
    <MainLayout>
      <div className="px-[64px] py-[96px] flex flex-col gap-[20px]">
        <div className="flex flex-col gap-[32px]">
          <p className="text-center text-[18px]">Current as of 11/28/2024</p>
          <h1 className="text-center text-[48px] font-semibold">
            Constructgraphy Cookie Policy
          </h1>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Introduction:</h1>
            <p>Our website uses cookies to enhance user experience.</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Cookies: We use:</h1>
            <p>1. Session cookies (temporary, deleted when browser closes)</p>
            <p>2. Persistent cookies (remain until expired or deleted)</p>
            <p>3. Third-party cookies (e.g., Google Analytics)</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Cookie Purposes:</h1>
            <p>1. Analyzing website traffic</p>
            <p>2. Improving website performance</p>
            <p>3. Personalizing content</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">
              Cookie Management: You can:
            </h1>
            <p>1. Accept cookies</p>
            <p>2. Decline cookies</p>
            <p>3. Manage cookies through browser settings</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">
              Third-Party Cookies: We use:
            </h1>
            <p>1. Google Analytics (website analytics)</p>
            <p>2. Social media sharing buttons (e.g., Facebook, Twitter)</p>
            <p>
              Cookie Retention: Cookies are retained for varying periods,
              depending on their purpose.
            </p>
            <p>Changes: We reserve the right to update this Cookie Policy.</p>
            <p>
              Contact Us: For questions or concerns, please contact:
              help@constructgraphy.com
            </p>
            <p>
              Cookie Settings: You can manage cookies through our website&#39;s
              cookie settings: [Insert cookie settings link or interface]
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CookiePolicy;
