import React, { useState } from "react";
import MainHeader from "../../components/shared/header/MainHeader";
import MainLayout from "../../components/shared/layouts/MainLayout";
import EmailIcon from "../../assets/images/png/email-icon.png";
import LocationIcon from "../../assets/images/png/location-icon.png";
import PhoneIcon from "../../assets/images/png/phone-icon.png";
import CustomImage from "../../components/shared/customImage";
import { Form, Formik } from "formik";
import InputText from "../../components/shared/input/InputText";
import ColleguesImage from "../../assets/images/png/workers-image.jpeg";
import Toolbar from "../../assets/images/png/toolbar.png";
import InputTextarea from "../../components/shared/input/InputTextarea";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: "",
    files: [],
  };

  const onSubmit = async (data: any) => {
    // try {
    //   if (requestType === "edit") {
    //     setLoading(true);
    //     const res = await updateExistingAggregator({
    //       ...data,
    //       id: useerData.id,
    //       programId: program,
    //     });
    //     if (res.code === "00") {
    //       toast.success("Aggregator updated");
    //       closeModal();
    //     }
    //     if (res.code !== "00") {
    //       setLoading(false);
    //       toast.error(res.message || "Error updating waybill");
    //     }
    //   }
    //   if (requestType !== "edit") {
    //     setLoading(true);
    //     const res = await createNewAggregator({
    //       ...data,
    //       programId: program,
    //     });
    //     if (res.code === "00") {
    //       toast.success("Aggregator created");
    //       closeModal();
    //     }
    //     if (res.code !== "00") {
    //       setLoading(false);
    //       toast.error(res.message || "Error creating Aggregator");
    //     }
    //   }
    // } catch (error) {
    //   toast.error(error.message || "something went wrong");
    // } finally {
    //   setLoading(false);
    //   setTimeout(() => {
    //     getAggregators();
    //   }, 1000);
    // }
  };
  return (
    <MainLayout>
      <div className="flex-grow py-[120px] px-[16px] lg:px-[112px] gap-[8px]">
        <div className="pb-[24px] flex flex-col gap-[20px]">
          <h1 className="text-[36px] font-semibold">Get in touch</h1>
          <p className="text-[20px]">
            Our friendly team would love to hear from you.
          </p>
        </div>
        <div className="py-[24px] bg-[#FAEEEA] flex flex-col lg:flex-row gap-[32px] lg:gap-[64px]">
          <div className="flex flex-col gap-[20px] items-center px-[32px] lg:w-[384px]">
            <div>
              <CustomImage src={EmailIcon} alt="email icon" />
            </div>
            <h1 className="font-semibold text-[20px]">Email</h1>
            <p className="text-[16px] font-normal">
              Our friendly team is here to help.
            </p>
            <p className="text-[#D1522E]">support@Constructgraphy.com</p>
          </div>
          <div className="flex flex-col gap-[20px] items-center px-[32px]">
            <div>
              <CustomImage src={LocationIcon} alt="email icon" />
            </div>
            <h1 className="font-semibold text-[20px]">Office</h1>
            <p className="text-[16px] font-normal">
              Come say hello at our office HQ.
            </p>
            <p className="text-[#D1522E]">
              100 Smith Street Collingwood VIC 3066 AU
            </p>
          </div>
          <div className="flex flex-col gap-[20px] items-center px-[32px]">
            <div>
              <CustomImage src={PhoneIcon} alt="email icon" />
            </div>
            <h1 className="font-semibold text-[20px]">Phone</h1>
            <p className="text-[16px] font-normal">Mon-Fri from 8am to 5pm.</p>
            <p className="text-[#D1522E]">+1 (555) 000-0000</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-[94px] pt-[56px] lg:relative">
          {/* <div className="h-auto"> */}
          <Formik
            initialValues={initialValues}
            validationSchema={{}}
            onSubmit={onSubmit}
          >
            {({
              handleChange,
              errors,
              values,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <div className="flex flex-col gap-[32px] min-w-[258px] md:min-w-[450px] lg:w-[258px]  bg-white p-4 rounded-[12px] ">
                    <div className="flex flex-col gap-[32px]">
                      <div className="flex flex-col gap-[16px]">
                        <div className="flex flex-col lg:flex-row justify-between gap-2">
                          <InputText
                            label={"First Name"}
                            name="name"
                            error={""}
                            value={values.name}
                            fieldRequired={true}
                            placeholder={"Enter business name"}
                            handleChange={handleChange}
                            unit={""}
                            type={""}
                            // className={""}
                            // textStyle={""}
                            setViewPassword={function (value: any): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                          <InputText
                            label={"Email Address"}
                            name="name"
                            error={""}
                            value={values.name}
                            fieldRequired={true}
                            placeholder={"Enter business name"}
                            handleChange={handleChange}
                            unit={""}
                            type={""}
                            // className={""}
                            // textStyle={""}
                            setViewPassword={function (value: any): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                        <div className="flex gap-2">
                          <InputText
                            label={"Phone Number"}
                            name="name"
                            error={""}
                            value={values.name}
                            fieldRequired={true}
                            placeholder={"Enter business name"}
                            handleChange={handleChange}
                            unit={""}
                            type={""}
                            inputClassName={""}
                            // textStyle={""}
                            setViewPassword={function (value: any): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                        <div className="flex gap-2">
                          <InputText
                            label={"Phone Number"}
                            name="name"
                            error={""}
                            value={values.name}
                            fieldRequired={true}
                            placeholder={"Enter business name"}
                            handleChange={handleChange}
                            unit={""}
                            type={""}
                            inputClassName={""}
                            // textStyle={""}
                            setViewPassword={function (value: any): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                        <div className="flex gap-2">
                          <InputTextarea
                            label={"Message"}
                            name="name"
                            error={""}
                            value={values.name}
                            fieldRequired={true}
                            placeholder={"Enter business name"}
                            handleChange={handleChange}
                            unit={""}
                            type={""}
                            className={"border border-gray-300 h-[134px]"}
                            textStyle={""}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-2 justify-center">
                      <button
                        type="submit"
                        disabled={loading ? true : false}
                        onClick={() => handleSubmit()}
                        className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2"
                      >
                        {loading ? "Creating..." : "Send Message"}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {/* </div> */}
          <div className="lg:w-[650px] lg:h-[614px]">
            <CustomImage src={ColleguesImage} alt="collegues" />
          </div>
          <div className="hidden lg:block absolute bottom-[-20px] left-[460px] lg:w-[318px] lg:h-[318px]">
            <CustomImage src={Toolbar} alt="collegues" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Contact;
