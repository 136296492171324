import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";

const DateInput = ({
  startDate,
  endDate,
  onChange,
}: {
  startDate: string | any;
  endDate: string | any;
  onChange(dates: any): void;
}) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    // <div className="flex flex-col gap-2 h-[44px]">
    <div
      className="border border-gray-300 p-2 rounded-md h-[40px] "
      onClick={onClick}
      ref={ref}
    >
      <h3 className="text-[12px] ">{value ? value : "Select date range"}</h3>
    </div>
    // </div>
  ));
  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<ExampleCustomInput />}
        wrapperClassName="w-full h-[44px]"
        // containerStyle={{ width: "100%" }}
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        isClearable
      />
    </>
  );
};

export default DateInput;
