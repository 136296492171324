import React from "react";
import CustomButton from "../button";
import { Link, useNavigate } from "react-router-dom";

const MainHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="px-[16px] lg:px-[64px] py-[18px]">
      <div className="flex justify-between items-center  lg:px-[64px] lg:py-[18px]">
        <div className="flex gap-[24px]">
          <div>
            <ul className="flex gap-[24px]">
              <Link to={"/about"} className="text-[#7D311C]">
                About us
              </Link>
              <Link to={"/contact"}>Contact</Link>
            </ul>
          </div>
        </div>
        <div className="flex items-center gap-[16px]">
          <CustomButton
            className="w-[95px] bg-[#A74225] h-[44px] text-white"
            label={"Log In"}
            handleClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
