import React, { Dispatch, SetStateAction, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { userRole } from "../../utils";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineLocalPhone } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import CustomImage from "../shared/customImage";
import AvatarAction from "../../assets/images/png/Avatar-actions.png";
import FolderCard from "../shared/cards/FolderCard";
import useFolders from "../../hooks/useFolders";

import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import Home from "../../assets/images/png/home.png";
import FolderIcon from "../../assets/images/png/foldercloud-Bulk.png";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const AdminUserProfileDetail = ({
  closeModal,
  data,
  setEditDetail,
  handleCreateFolder,
  handleAddMedia,
  handleDelete,
}: // folders,
{
  closeModal(): void;
  data: any;
  setEditDetail(): void;
  handleAddMedia(): void;
  handleCreateFolder(): void;
  handleDelete?(): void;
  // folders: any[];
}) => {
  const navigate = useNavigate();
  const { folders, loading, getFolders } = useFolders({
    owner: data?.role === "OWNER" ? data?._id : "",
    builder: data?.role === "BUILDER" ? data?._id : "",
  });
  console.log({ folders });
  return (
    <div className="flex flex-col gap-y-2 w-[576px]  bg-white p-4 rounded-[12px] h-max overflow-y-auto ">
      <div className="pt-[24px] flex  gap-[16px]">
        <div className="w-[48px] h-[48px] border border-gray-300 flex justify-center items-center rounded-[10px]">
          <FiUser />
        </div>
        <div className="flex-grow flex justify-between">
          <div>
            <h2 className="font-semibold">Profile Details</h2>
            <p className="text-[14px]">You can edit the usersdetails </p>
          </div>
          <div></div>
          <AiOutlineClose
            onClick={() => closeModal()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="border-t border-gray-300">
        <div className="h-[1px]"></div>
      </div>
      <div className="flex flex-col gap-[40px]">
        <div className="">
          <div className="flex justify-between">
            <div>
              <h2 className="font-semibold">Profile Details</h2>
              <p className="text-[14px]">The Users details</p>
            </div>
            <div className="flex items-center gap-[12px]">
              <RiDeleteBin6Line
                onClick={() => {
                  if (handleDelete) {
                    handleDelete();
                  }
                }}
                style={{ cursor: "pointer" }}
              />
              <button
                className="border border-gray-300 py-[10px] px-[16px] rounded-[8px]"
                onClick={() => {
                  setEditDetail();
                  closeModal();
                }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-300">
          <div className="h-[1px]"></div>
        </div>
        <div className="flex gap-[16px]">
          <div className="w-[96px] h-[96px]">
            <CustomImage
              src={data?.profileImage ? data?.profileImage : AvatarAction}
              alt="avatar"
            />
          </div>
          <div>
            <div className="flex gap-[8px]">
              <h1 className="text-[20px] font-semibold">{data?.fullName}</h1>
              <span
                className={`${
                  data?.role === "BUILDER"
                    ? "bg-[#EFF8FF] border-[#175CD3]"
                    : data?.role === "OWNER"
                    ? "bg-[#FDF2FA] border-[#C11574]"
                    : data?.role === "SUPER_ADMIN"
                    ? "bg-[#FEF6EE] border-[#B93815]"
                    : data?.role === "ADMIN"
                    ? "bg-[#F9FAFB] border-[#344054]"
                    : ""
                } rounded-[16px] w-max flex items-center gap-[4px] py-[2px] pr-[8px] pl-[6px]`}
              >
                <span
                  className={`${
                    data?.role === "BUILDER"
                      ? "bg-[#175CD3]"
                      : data?.role === "OWNER"
                      ? "bg-[#C11574]"
                      : data?.role === "SUPER_ADMIN"
                      ? "bg-[#B93815]"
                      : data?.role === "ADMIN"
                      ? "bg-[#344054]"
                      : ""
                  } w-[6px] h-[6px] rounded-full inline-block`}
                ></span>
                <span
                  className={`${
                    data?.role === "BUILDER"
                      ? "text-[#175CD3]"
                      : data?.role === "OWNER"
                      ? "text-[#C11574]"
                      : data?.role === "SUPER_ADMIN"
                      ? "text-[#B93815]"
                      : data?.role === "ADMIN"
                      ? "text-[#344054]"
                      : ""
                  } text-[12px] font-medium capitalize`}
                >
                  {userRole(data?.role)}
                </span>
              </span>
            </div>
            <div className="flex items-center gap-[8px]">
              <MdOutlineEmail />
              <span className="text-[16px] font-normal">{data?.email}</span>
            </div>
            <div className="flex items-center gap-[8px]">
              <MdOutlineLocalPhone className="w-[24px] h-[24px]" />
              <span className="text-[16px] font-normal">
                {data?.phoneNumber}
              </span>
            </div>
            <div className="flex gap-[8px]">
              <CiLocationOn className="w-[24px] h-[24px]" />
              <span className="text-[16px] font-normal">{data?.address}</span>
            </div>
          </div>
        </div>
        {/* <div className="">
          <div className="flex justify-between">
            <div>
              <h2 className="font-semibold">Files</h2>
              <p className="text-[14px]">
                Has access to {folders.length || 0}{" "}
                {folders.length === 0 || folders.length === 1
                  ? "folder"
                  : "folders"}
              </p>
            </div>
            <div className="flex items-center gap-[12px]">
              <button
                className="border border-gray-300 py-[10px] px-[16px] rounded-[8px] h-[40px]"
                onClick={() => {
                  handleAddMedia();
                }}
              >
                Add Media
              </button>
              <button
                className="border border-gray-300 py-[10px] px-[16px] rounded-[8px] h-[40px] bg-[#A74225] text-white"
                onClick={() => {
                  handleCreateFolder();
                }}
              >
                Create Folder
              </button>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="border-t border-gray-300">
        <div className="h-[1px]"></div>
      </div> */}
      {/* <div className="flex flex-wrap gap-[20px]">
        {folders?.map((data: any, index: number) => {
          return (
            <div
              className="flex flex-col lg:w-[380px] lg:h-[312px]  rounded-[12px] border border-gray-300 cursor-pointer"
              // onClick={() => navigate(`/admin/folders/${data?._id}/detail`)}
              key={index}
            >
              <div className="flex justify-between items-center pt-[7.83px] px-[11.74px]">
                <div className="w-[15.69px] lg:w-[32px]">
                  <CustomImage src={FolderIcon} alt="folder" />
                </div>
                <div className=" flex gap-[8px]">
                  <AiOutlineHeart className="w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]" />
                  <AiOutlineMore className="w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]" />
                </div>
              </div>
              <div className=" pt-[7.83px] px-[11.74px] lg:py-[16px] lg:px-[24px]">
                <h1 className="text-[7.83px] lg:text-[16px] font-semibold capitalize">
                  {data.folderName}
                </h1>
                <p className="text-[3.91px] lg:text-[8px] lg:whitespace-wrap">
                  {data?.owner?.address}
                </p>
              </div>
              <div className="px-[11.74px]">
                <div className="lg:w[376px]">
                  <CustomImage src={Home} alt="home" />
                </div>
              </div>
              <div className="flex justify-between py-[7.83px] px-[11.74px] lg:py-[16px] lg:px-[24px]">
                <p className=" lg:w-[160px] text-[5.87px] lg:text-[12px] whitespace-nowrap">
                  {data?.files?.length}{" "}
                  {`${data?.files?.length > 1 ? "files" : "file"}`}
                </p>
                <p className="whitespace-nowrap text-[5.87px] lg:text-[12px]">
                  Created {moment(data?.createdAt).format("MMMM DD, YYYY")}
                </p>
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default AdminUserProfileDetail;
