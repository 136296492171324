import React, { useRef, useState } from "react";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import CustomImage from "../../../components/shared/customImage";
import Home from "../../../assets/images/png/home.png";
import FolderIcon from "../../../assets/images/png/foldercloud-Bulk.png";
import { FaDiamond } from "react-icons/fa6";
import moment from "moment";
import { formatBytes } from "../../../utils";
import { FaHeart } from "react-icons/fa";

const FolderCard = ({
  data,
  favourite,
  handleClick,
  handleFavourite,
  handleUnFavourite,
  ownerId,
  builderID,
}: any) => {
  return (
    <>
      <div className="flex flex-col w-[180.96px] h-[78.25px] lg:w-[313px] lg:h-[160px]  rounded-[12px] border border-gray-300 cursor-pointer">
        <div className="lg:pb-[16px] lg:pt-[20px] lg:px-[24px]">
          <div className="flex justify-between items-center pt-[7.83px] px-[11.74px] lg:px-0">
            <div className="w-[15.69px] lg:w-[32px]">
              <CustomImage src={FolderIcon} alt="folder" />
            </div>
            <div className=" flex gap-[8px]">
              {favourite ? (
                <FaHeart
                  className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
                  style={{ color: "red" }}
                  onClick={() => {
                    if (handleUnFavourite) {
                      handleUnFavourite();
                    }
                  }}
                />
              ) : (
                <AiOutlineHeart
                  className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
                  style={{ backgroundColor: `${favourite ? "red" : ""}` }}
                  onClick={() => {
                    if (handleFavourite) {
                      handleFavourite();
                    }
                  }}
                />
              )}
              <AiOutlineMore className="w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]" />
            </div>
          </div>
          <div
            className="flex flex-col gap-[8px]"
            onClick={() => {
              handleClick();
            }}
          >
            <div className=" pt-[7.83px] px-[11.74px] lg:px-0">
              <h1 className="text-[7.83px] lg:text-[16px] font-semibold">
                {data?.name}
              </h1>
              {/* <p className="text-[3.91px] lg:text-[8px] lg:whitespace-wrap">
                {data?.address}
              </p> */}
            </div>
            {/* <div className="">
              <div className="lg:w-[376px] lg:h-[130px]">
                <CustomImage src={Home} alt="home" />
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex justify-between py-[7.83px] px-[11.74px] lg:pb-[16px] lg:px-[24px]">
          <div className="flex items-center lg:w-[160px] gap-[8px]">
            <p className="text-[5.87px] lg:text-[12px] whitespace-nowrap">
              {data?.files?.length || 0}{" "}
              {`${data?.files?.length > 1 ? "files" : "file"}`}
            </p>
            <FaDiamond className="w-[5px] h-[5px]" />
            <p className="text-[5.87px] lg:text-[12px]">
              {formatBytes(
                data?.files?.reduce(
                  (prev: any, curr: any) => prev + curr.size,
                  0
                )
              )}
            </p>
          </div>
          <p className="whitespace-nowrap text-[5.87px] lg:text-[12px]">
            Created {moment(data?.createdAt).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </>
  );
};

export default FolderCard;
