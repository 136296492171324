import React from "react";
import { AiOutlineAlignLeft } from "react-icons/ai";
import CustomImage from "../customImage";
import MobileLogo from "../../../assets/images/png/mobile-logo.png";

const AdminAuthHeader = () => {
  return (
    <div className="flex justify-center items-center py-[18px] px-[64px]">
      <div className="w-[206px]">
        <CustomImage src={MobileLogo} alt="logo" />
      </div>
    </div>
  );
};

export default AdminAuthHeader;
