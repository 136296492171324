import React from "react";
import AuthLayout from "../../components/shared/layouts/AuthLayout";
import MainLayout from "../../components/shared/layouts/MainLayout";

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <div className="px-[64px] py-[96px] flex flex-col gap-[20px]">
        <div className="flex flex-col gap-[32px]">
          <p className="text-center text-[18px]">Current as of 11/28/2024</p>
          <h1 className="text-center text-[48px] font-semibold">
            Constructgraphy Privacy Policy
          </h1>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Introduction:</h1>
            <p>
              Constructgraphy (“we”, “us”, or “our”;)respects your privacy. This
              Privacy Policy outlines how we collect, use, disclose, and protect
              your personal information
            </p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Personal Information:</h1>
            <p>We collect personal information from:</p>
            <p>1. Clients (name, email, phone number, address)</p>
            <p>2. Website users (IP address, browser type, pages visited)</p>
            <p>3. Social media interactions (username, profile information)</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Purpose</h1>
            <p>We collect personal information for:</p>
            <p>1. Providing construction photography services</p>
            <p>2. Marketing and promotional purposes</p>
            <p>3. Improving website user experience</p>
            <p>4. Complying with legal requirements</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Disclosure</h1>
            <p>We may disclose personal information to:</p>
            <p>1. Service providers (e.g., payment processors)</p>
            <p>2. Affiliated companies</p>
            <p>3. Law enforcement agencies (as required by law)</p>
            <p>
              Consent: By using our services or website, you consent to the
              collection, use, and disclosure of your personal information.
            </p>
            <p>
              Retention: We retain personal information for as long as necessary
              to fulfill the purposes outlined above.
            </p>
            <p>
              Security: We implement reasonable security measures to protect
              personal information.
            </p>
            <p>Access and Correction: You have the right to:</p>
            <p>1. Access your personal information</p>
            <p>2. Correct inaccuracies</p>
            <p>3. Withdraw consent</p>
            <p>
              Contact Us: For questions or concerns, please contact:
              help@constructgraphy.com
            </p>
            <p>Compliance: This Privacy Policy complies with:</p>
            <p>
              1. Personal Information Protection and Electronic Documents Act
              (PIPEDA)
            </p>
            <p>2. Canada’s Anti-Spam Legislation (CASL)</p>
            <p>3. Provincial privacy laws (as applicable)</p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PrivacyPolicy;
