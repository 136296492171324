import React from "react";
import * as AccordionPrimitives from "@radix-ui/react-accordion";
import classnames from "classnames";
// import { ChevronDownIcon } from "@radix-ui/react-icons";

const Accordion = AccordionPrimitives.Root;
Accordion.defaultProps = {
  className: "AccordionRoot",
};
const AccordionItem = AccordionPrimitives.Item;
AccordionItem.defaultProps = {
  className: "AccordionItem",
};

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }: any, forwardedRef) => (
    <AccordionPrimitives.Header className="AccordionHeader">
      <AccordionPrimitives.Trigger
        className={classnames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        {/* <ChevronDownIcon className="AccordionChevron" aria-hidden /> */}
        {/* <Icon icon="chevron-down" className="accordion-chevron" size="20px" /> */}
      </AccordionPrimitives.Trigger>
    </AccordionPrimitives.Header>
  )
);

AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }: any, forwardedRef) => (
    <AccordionPrimitives.Content
      className={classnames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText w-[322px]">{children}</div>
    </AccordionPrimitives.Content>
  )
);

AccordionContent.displayName = "AccordionContent";

export { Accordion, AccordionItem, AccordionContent, AccordionTrigger };
