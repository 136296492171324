import React from "react";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";

const AdminAuthFooter = () => {
  return (
    <div className="flex justify-center items-center lg:py-[32px] lg:px-[64px]">
      <div className="flex flex-col lg:flex-row lg:justify-between w-full pt-[32px] border-t border-gray-300">
        <div className="text-[16px]">
          © 2024 Constructgraphy. All rights reserved.
        </div>
        <div>
          <ul className="flex gap-[24px]">
            <li>
              <FaTwitter className="w-[24px] h-[24px]" />
            </li>
            <li>
              <FaLinkedin className="w-[24px] h-[24px]" />
            </li>
            <li>
              <FaFacebook className="w-[24px] h-[24px]" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminAuthFooter;
