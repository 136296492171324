import React, { Children } from "react";
import DashboardLayout from "../components/shared/layouts/DashboardLayout";
import Dashboard from "../pages/dashboard";
import BuilderDashboard from "../pages/dashboard/builder";
import OwnerDetail from "../pages/dashboard/builder/OwnerDetail";
// import Admin from "../pages/dashboard/admin";
import AdminLayout from "../components/shared/layouts/AdminLayout";
import AdminDashboard from "../pages/admin/dashboard";
import UserManagement from "../pages/admin/user-management";
import AdminManagement from "../pages/admin/admin-management";
import AuthGuard from "../route-guard/AuthGuard";
import ProjectDetail from "../pages/project/ProjectDetail";
import AdminDashGuard from "../route-guard/AdminDashGuard";
import FolderDetail from "../pages/folder/FolderDetail";
import AdminProjectDetail from "../pages/admin/project/AdminProjectDetail";
import AdminFolderDetail from "../pages/admin/folder/AdminFolderDetail";
import Favourite from "../pages/favourite";

interface DashboardRouteProps {
  path: string;
  element: JSX.Element;
  children: { children: { path: string; element: JSX.Element }[] }[];
}
export const getDashboardRouter = () => {
  return [
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          children: [
            {
              path: "/dashboard",
              element: <Dashboard />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/users/favourite",
              element: <Favourite />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/folders/:id",
              element: <FolderDetail />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/folders/:id/detail",
              element: <ProjectDetail />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "/projects/:projectId",
              element: <ProjectDetail />,
              // errorElement: <ErrorBoundary />,
            },
          ],
        },
      ],
    },

    // {
    //   path: "/projects",
    //   element: <></>,
    //   // errorElement: <ErrorBoundary />,
    //   children: [
    //     {
    //       children: [
    //         {
    //           path: "/dashboard",
    //           element: <OwnerDashboard />,
    //           // errorElement: <ErrorBoundary />,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      path: "admin",
      element: (
        <AdminDashGuard>
          <AdminLayout />
        </AdminDashGuard>
      ),
      // errorElement: <ErrorBoundary />,
      children: [
        {
          children: [
            {
              path: "dashboard",
              element: <AdminDashboard />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "user-management",
              element: <UserManagement />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "admin-management",
              element: <AdminManagement />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "folders/:id",
              element: <AdminFolderDetail />,
              // errorElement: <ErrorBoundary />,
            },
            {
              path: "folders/:id/detail",
              element: <AdminProjectDetail />,
              // errorElement: <ErrorBoundary />,
            },
            // {
            //   path: "login",
            //   element: <AdminSignin />,
            //   // errorElement: <ErrorBoundary />,
            // },
            // {
            //   path: "forgotpassword",
            //   element: <AdminForgotPassword />,
            //   // errorElement: <ErrorBoundary />,
            // },
            // {
            //   path: "user",
            //   element: <User />,
            //   // errorElement: <ErrorBoundary />,
            // },
            // {
            //   path: "offtake",
            //   element: <Offtake />,
            //   // errorElement: <ErrorBoundary />,
            // },
            // {
            //   path: "collection",
            //   element: <Collection />,
            //   // errorElement: <ErrorBoundary />,
            // },
            // {
            //   path: "/dashboard/:modelId",
            //   element: <DashboardLayout />,
            //   errorElement: <ErrorPage />,
            // },
          ],
        },
      ],
    },
  ];
};
