import React, { useRef, useState } from "react";
import DashboardLayout from "../../components/shared/layouts/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import Home from "../../../assets/images/png/home.png";
import CustomImage from "../../components/shared/customImage";
import { TiFolder } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";
import ProjectCard from "../../components/shared/cards/ProjectCard";
import useProjects from "../../hooks/useProject";
import FileCard from "../../components/shared/cards/FileCard";
import useFolders from "../../hooks/useFolders";
import moment from "moment";
import { formatBytes } from "../../utils";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/shared/modalWrapper/Modal";
import ViewMedia from "../../components/modal/ViewMedia";
import { useSelector } from "react-redux";
const ProjectDetail = () => {
  const { id } = useParams();
  const wrapperRef = useRef(null);
  const [viewFile, setViewFile] = useOutsideClick(wrapperRef);
  const { islogin, user } = useSelector((state: any) => state.auth);
  const [file, setFile] = useState();
  const { folder } = useFolders({ id: id });
  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[12px] w-full py-[24px] px-[16px] lg:px-[64px] lg:py-[48px]">
        <div>
          <div className="flex items-center gap-[8px]">
            <TiFolder className="w-[28px] h-[28px]" />
            {user?.role === "BUILDER" ? (
              <>
                <MdOutlineChevronRight className="w-[16px] h-[16px]" />
                <h1 className="text-[14px] font-semibold">
                  {`${folder?.data?.owner.firstName} ${folder?.data?.owner?.lastName}`}
                </h1>
              </>
            ) : (
              ""
            )}
            <MdOutlineChevronRight className="w-[16px] h-[16px]" />
            <h1 className="text-[14px] font-semibold">
              {folder?.data?.folderName}
            </h1>
          </div>
        </div>
        <h1 className="text-[30px] font-semibold">
          {folder?.data?.folderName}
        </h1>
        <div className="flex flex-col gap-y-[12px]">
          <p className="underline text-[#2E90FA]">
            {folder?.data?.owner?.address}
          </p>
          <p>
            Created {moment(folder?.data?.createdAt).format("MMMM DD, YYYY")}
          </p>
          <p>
            {formatBytes(
              folder?.data?.files?.reduce(
                (prev: any, curr: any) => prev + curr.size,
                0
              )
            )}
          </p>
        </div>
        <div className="border-t border-gray-300">
          <div className="pt-[24px]"></div>
        </div>
      </div>
      {/* <div className="flex flex-col items-center px-[64px] lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full"> */}
      <div className="flex flex-row px-[16px] lg:px-[64px] flex-wrap items-center lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full">
        {folder?.data?.files.map((data: any, index: number) => {
          return (
            <FileCard
              data={data}
              handleClick={() => {
                setViewFile(true);
                setFile(data);
              }}
              favourite={false}
              key={index}
            />
          );
        })}
      </div>
      {viewFile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewFile(false)}
        >
          <ViewMedia
            closeModal={() => setViewFile(false)}
            data={file}
            // getAllData={getUsers}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectDetail;

// <div className="flex flex-col items-center w-[370px] lg:w-[316px] h-[243px] rounded-[12px] border border-gray-300">
//   <div className="p-[16px] flex">
//     <p className="w-[290px] lg:w-[236px]">Painting work.jpg</p>
//     <div className="flex gap-[8px]">
//       <AiOutlineHeart className="w-[20px] h-[20px]" />
//       <AiOutlineMore className="w-[20px] h-[20px]" />
//     </div>
//   </div>
//   <div>
//     <div>
//       <CustomImage src={Home} alt="home" />
//     </div>
//   </div>
//   <div className="p-[16px] flex justify-between">
//     <p className="w-[214px] lg:w-[160px] text-[12px]">50 MB</p>
//     <p className="whitespace-nowrap text-[12px]">
//       Uploaded Oct 1, 2024
//     </p>
//   </div>
// </div>
