import React from "react";

const CustomVideo = ({ src, type }: any) => {
  return (
    <video width={"100%"} height={"100%"}>
      <source src={src} type={type} media="(min-width: )" />
    </video>
  );
};

export default CustomVideo;
