import React from "react";
import { uploadFile, uploadOneFile } from "../ds/upload";
// import { signUp } from "aws-amplify/auth";

const useUploads = () => {
  const uploadFiles = async (data: any) => {
    const response = await uploadFile(data);
    return response;
  };
  const uploadImage = async (data: any) => {
    const response = await uploadOneFile(data);
    return response;
  };

  return {
    uploadFiles,
    uploadImage,
  };
};

export default useUploads;
