import React, { useRef, useState } from "react";
import CustomTable from "../../../components/shared/table/CustomTable";
import { CiCirclePlus } from "react-icons/ci";
import { MdFilterList } from "react-icons/md";
import DateInput from "../../../components/shared/DateInput";
import { FiEdit2 } from "react-icons/fi";
import Modal from "../../../components/shared/modalWrapper/Modal";
import useOutsideClick from "../../../hooks/useOutsideClick";
import CreateFolderModal from "../../../components/modal/CreateFolderModal";
import UserModal from "../../../components/modal/UserModal";
import useUsers from "../../../hooks/useUsers";
import InputSearch from "../../../components/shared/input/InputSearch";
import { userRole } from "../../../utils";
import AdminUserProfileDetail from "../../../components/modal/AdminUserProfileDetail";
import moment from "moment";
import PaginationTab from "../../../components/shared/pagination/PaginationTab";
import Footer from "../../../components/shared/footer";
import AdminAuthFooter from "../../../components/shared/footer/AdminAuthFooter";
import AdminManagementFooter from "../../../components/shared/footer/AdminManagementFooter";
import useFolders from "../../../hooks/useFolders";
import UploadImageModal from "../../../components/modal/UploadImageModal";
// import PaginationTab from "../../../components/shared/table/PaginationTab";

const headers = [
  "Full Name",
  "Email Address",
  "Phone Number",
  "Role",
  "Created Date",
];

const UserManagement = () => {
  const wrapperRef = useRef(null);
  const [createUser, setCreateUser] = useOutsideClick(wrapperRef);
  const [createFolder, setCreateFolder] = useOutsideClick(wrapperRef);
  const [uploadImage, setUploadImage] = useOutsideClick(wrapperRef);
  const [showUserDetail, setshowUserDetail] = useOutsideClick(wrapperRef);
  const [editUserDetail, setEditUserDetail] = useOutsideClick(wrapperRef);
  const [page, setPage] = useState<number>(1);
  const [role, setRole] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [userDetail, setUserDetail] = useState<any>({});
  const { users, loading, getUsers } = useUsers({
    role: role,
    page,
    limit,
    search: query,
  });
  const {
    folders,
    loading: folderLoading,
    getFolders,
  } = useFolders({
    owner: userDetail?._id,
  });
  const { users: builders } = useUsers({ role: "BUILDER" });
  const { users: owners } = useUsers({ role: "OWNER" });
  const { deleteUserDetail } = useUsers({});
  console.log({ folders });

  const handlePageChange = (data: any) => {
    if (data === 0) {
      setPage(1);
    }
    setPage(data + 1);
  };

  const nextPage = () => {
    if (page === users?.totalPages) {
      setPage(page);
    }
    if (page < users?.totalPages) {
      setPage((prev) => (page >= 1 && page !== users ? prev + 1 : prev));
    }
    // getAllActiveFo(query, selectedState, selectedLga);
  };
  const prevPage = () => {
    if (page === users?.totalPages) {
      setPage(1);
    }
    if (page !== 0) {
      setPage((prev) =>
        page >= 1 || page === users?.totalPages ? prev - 1 : prev
      );
    }
    // getAllActiveFo(query, selectedState, selectedLga);
  };

  return (
    <div className="flex flex-col gap-[8px] pt-[32px] pr-[32px] pb-[8px] pl-[24px]">
      <div className="flex flex-col gap-[16px] pb-[32px]">
        <h1 className="font-semibold text-[24px]">User Management</h1>
        <p className="text-[16px]">Manage Builders and Homeowner Accounts.</p>
      </div>
      <div className="border border-gray-300 rounded-[8px]">
        <div className="lg:gap-0 lg:flex-row lg:justify-between pt-[16px] pb-[24px] ">
          <div className="flex justify-between py-[12px] px-[16px]">
            <div className="flex rounded-[8px] w-max ">
              {[
                { name: "View All", value: "" },
                { name: "Home Owner", value: "OWNER" },
                { name: "Builder", value: "BUILDER" },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 py-[10px] px-[8px] text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${index === 0 ? "rounded-tl rounded-bl" : ""} ${
                      data?.value === role
                        ? "text-sm rounded-tl rounded-bl bg-[#A74225] text-white"
                        : ""
                    }`}
                    onClick={() => {
                      setRole(data.value);
                    }}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div>
            <div className="flex gap-[12px]">
              <InputSearch
                placeholder="Search"
                inputValue={query}
                setInputValue={setQuery}
              />
              <button
                className="flex w-[181px] text-[14px] text-white gap-[8px] h-[44px] bg-[#A74225] items-center border border-gray-300 py-[10px] px-[16px] capitalize rounded-[8px]"
                onClick={() => setCreateUser(true)}
              >
                <CiCirclePlus />
                Create new user
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : users?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={users.map((data: any, index: number) => {
              return {
                fullname: `${data?.firstName} ${data.lastName}`,
                email: data.email,
                phoneNumber: data.phoneNumber,
                role: (
                  <span
                    className={`${
                      data?.role === "BUILDER"
                        ? "bg-[#EFF8FF] border-[#175CD3]"
                        : data?.role === "OWNER"
                        ? "bg-[#FDF2FA] border-[#C11574]"
                        : data?.role === "SUPER_ADMIN"
                        ? "bg-[#FEF6EE] border-[#B93815]"
                        : data?.role === "ADMIN"
                        ? "bg-[#F9FAFB] border-[#344054]"
                        : ""
                    } rounded-[16px] w-max flex items-center gap-[4px] py-[2px] pr-[8px] pl-[6px]`}
                  >
                    <span
                      className={`${
                        data?.role === "BUILDER"
                          ? "bg-[#175CD3]"
                          : data?.role === "OWNER"
                          ? "bg-[#C11574]"
                          : data?.role === "SUPER_ADMIN"
                          ? "bg-[#B93815]"
                          : data?.role === "ADMIN"
                          ? "bg-[#344054]"
                          : ""
                      } w-[6px] h-[6px] rounded-full inline-block`}
                    ></span>
                    <span
                      className={`${
                        data?.role === "BUILDER"
                          ? "text-[#175CD3]"
                          : data?.role === "OWNER"
                          ? "text-[#C11574]"
                          : data?.role === "SUPER_ADMIN"
                          ? "text-[#B93815]"
                          : data?.role === "ADMIN"
                          ? "text-[#344054]"
                          : ""
                      } text-[12px] capitalize`}
                    >
                      {userRole(data?.role)}
                    </span>
                  </span>
                ),
                date: moment(data?.createdAt).format("MMMM DD, YYYY | HH:MM A"),
                open: (
                  <FiEdit2
                    key={index}
                    className="w-[20px] h-[20px]"
                    onClick={() => {
                      setshowUserDetail(true);
                      setUserDetail(data);
                    }}
                  />
                ),
              };
            })}
            tableTitle={undefined}
            containerWidth={undefined}
            boxShadow={undefined}
            tableButton={undefined}
            setAllChecked={undefined}
          />
        )}
        <div className="pt-[20px] px-[24px] pb-[24px]">
          <PaginationTab
            pageIndex={page}
            pageCount={users?.totalPages}
            gotoPage={handlePageChange}
            // pageSize={10}
            canPreviousPage={""}
            canNextPage={""}
            handleNext={() => nextPage()}
            handlePrevious={() => prevPage()}
            // pageOptions,
            setPageSize={""}
            // useAlternatePagination={false}
          />
        </div>
      </div>
      <AdminManagementFooter />
      {createUser && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setCreateUser(false)}
        >
          <UserModal
            closeModal={() => setCreateUser(false)}
            getAllData={getUsers}
          />
        </Modal>
      )}
      {editUserDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditUserDetail(false)}
        >
          <UserModal
            closeModal={() => setEditUserDetail(false)}
            requestType={"edit"}
            getAllData={getUsers}
            userData={userDetail}
          />
        </Modal>
      )}
      {createFolder && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setCreateFolder(false)}
        >
          <CreateFolderModal
            closeModal={() => setCreateFolder(false)}
            loadOptionsOne={builders.map((data: any) => {
              return {
                label: data.fullName,
                value: data._id,
              };
            })}
            loadOptionsTwo={owners.map((data: any) => {
              return {
                label: data.fullName,
                value: data._id,
              };
            })}
            getData={getFolders}
          />
        </Modal>
      )}

      {uploadImage && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setUploadImage(false)}
        >
          <UploadImageModal
            closeModal={() => setUploadImage(false)}
            data={folders}
            getData={getFolders}
          />
        </Modal>
      )}
      {showUserDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setshowUserDetail(false)}
        >
          <AdminUserProfileDetail
            closeModal={() => setshowUserDetail(false)}
            data={userDetail}
            setEditDetail={() => setEditUserDetail(true)}
            handleAddMedia={() => {
              setUploadImage(true);
              setshowUserDetail(false);
            }}
            handleCreateFolder={() => {
              setCreateFolder(true);
              setshowUserDetail(false);
            }}
            handleDelete={() => {
              deleteUserDetail(userDetail?._id);
              getUsers();
              setshowUserDetail(false);
            }}
            // folders={folders}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserManagement;
