import React, { Dispatch, SetStateAction, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { userRole } from "../../utils";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlineLocalPhone } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import AvatarAction from "../../assets/images/png/Avatar-actions.png";
import CustomImage from "../shared/customImage";
const AdminDetail = ({
  closeModal,
  data,
  setEditDetail,
  handleDelete,
}: {
  closeModal(): void;
  data: any;
  setEditDetail(): void;
  handleDelete?(): void;
}) => {
  return (
    <div className="flex flex-col gap-y-2 min-w-[258px] md:min-w-[450px] lg:w-[576px]  bg-white p-4 rounded-[12px] ">
      <div className="px-[24px] pt-[24px] flex  gap-[16px]">
        <div className="w-[48px] h-[48px] border border-gray-300 flex justify-center items-center rounded-[10px]">
          <FiUser />
        </div>
        <div className="flex-grow flex justify-between">
          <div>
            <h2 className="font-semibold">Admin Details</h2>
            <p className="text-[14px]">You can edit the admin details</p>
          </div>
          <div></div>
          <AiOutlineClose
            onClick={() => closeModal()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="border-t border-gray-300 pt-[12px] pb-[12px]">
        <div className="h-[1px]"></div>
      </div>
      <div className="px-[24px]">
        <div className="flex justify-between">
          <div>
            <h2 className="font-semibold">Admin Details</h2>
            <p className="text-[14px]">The admin details</p>
          </div>
          <div className="flex items-center gap-[12px]">
            <RiDeleteBin6Line
              onClick={() => {
                if (handleDelete) {
                  handleDelete();
                }
              }}
              style={{ cursor: "pointer" }}
            />
            <button
              className="border border-gray-300 py-[10px] px-[16px] rounded-[8px]"
              onClick={() => {
                setEditDetail();
                closeModal();
              }}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-300 pt-[12px] pb-[12px]">
        <div className="h-[1px]"></div>
      </div>
      <div className="px-[24px]">
        <div className="w-[96px] h-[96px]">
          <CustomImage
            src={data?.profileImage ? data?.profileImage : AvatarAction}
            alt="avatar"
          />
        </div>
        <div>
          <div className="flex gap-[8px]">
            <h1 className="text-[20px] font-semibold">{data?.fullName}</h1>
            <span
              className={`${
                data?.role === "BUILDER"
                  ? "bg-[#EFF8FF] border-[#175CD3]"
                  : data?.role === "OWNER"
                  ? "bg-[#FDF2FA] border-[#C11574]"
                  : data?.role === "SUPER_ADMIN"
                  ? "bg-[#FEF6EE] border-[#B93815]"
                  : data?.role === "ADMIN"
                  ? "bg-[#F9FAFB] border-[#344054]"
                  : ""
              } rounded-[16px] w-max flex items-center gap-[4px] py-[2px] pr-[8px] pl-[6px]`}
            >
              <span
                className={`${
                  data?.role === "BUILDER"
                    ? "bg-[#175CD3]"
                    : data?.role === "OWNER"
                    ? "bg-[#C11574]"
                    : data?.role === "SUPER_ADMIN"
                    ? "bg-[#B93815]"
                    : data?.role === "ADMIN"
                    ? "bg-[#344054]"
                    : ""
                } w-[6px] h-[6px] rounded-full inline-block`}
              ></span>
              <span
                className={`${
                  data?.role === "BUILDER"
                    ? "text-[#175CD3]"
                    : data?.role === "OWNER"
                    ? "text-[#C11574]"
                    : data?.role === "SUPER_ADMIN"
                    ? "text-[#B93815]"
                    : data?.role === "ADMIN"
                    ? "text-[#344054]"
                    : ""
                } text-[12px] font-medium capitalize`}
              >
                {userRole(data?.role)}
              </span>
            </span>
          </div>
        </div>
        <div className="flex items-center gap-[8px]">
          <MdOutlineEmail />
          <span className="text-[16px] font-normal">{data?.email}</span>
        </div>
        <div className="flex items-center gap-[8px]">
          <MdOutlineLocalPhone className="w-[24px] h-[24px]" />
          <span className="text-[16px] font-normal">{data?.phoneNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default AdminDetail;
