import React from "react";
import Footer from "../footer";
import AuthHeader from "../header/AuthHeader";

interface AuthLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}
const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className="flex flex-col">
      <AuthHeader />
      {children}
      <Footer />
    </div>
  );
};

export default AuthLayout;
