import React from "react";
import AdminAuthHeader from "../header/AdminAuthHeader";
import AdminAuthFooter from "../footer/AdminAuthFooter";

interface AuthLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}
const AdminAuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <div className="flex flex-col h-screen">
      <AdminAuthHeader />
      {children}
      <AdminAuthFooter />
    </div>
  );
};

export default AdminAuthLayout;
