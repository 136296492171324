import axiosInstance from "../api/axios";

export const getAllUsers = async ({
  page,
  limit,
  role,
  search,
  startDate,
  endDate,
}: {
  page?: number;
  limit?: number;
  role?: string;
  search?: string;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
}) => {
  try {
    const accessToken = localStorage.getItem("token");
    const params: any = {};
    if (role) {
      params.role = role;
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (search) {
      params.search = search;
    }
    if (startDate && endDate) {
      params.startDate = startDate;
      params.endDate = endDate;
    }
    const res = await axiosInstance.get(`/users`, {
      params,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAllAdmins = async ({
  page,
  limit,
  role,
  search,
}: {
  page?: number;
  limit?: number;
  role?: string;
  search?: string;
}) => {
  try {
    const accessToken = localStorage.getItem("token");
    const params: any = {};
    if (role) {
      params.role = role;
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (search) {
      params.search = search;
    }
    const res = await axiosInstance.get(`/admins`, {
      params,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id: string, data: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.patch(`/users/${id}`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getUser = async (id: string) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.get(`/users/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const deleteUser = async (id: string) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.delete(`/users/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getUserProfile = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.get(`/users/profile/detail`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const getDashboardStats = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance.get(`/dashboard/report`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const addFolderToFavourite = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/users/favourite/folder`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const addFileToFavourite = async (data: any) => {
  try {
    const res = await axiosInstance.post(`/users/favourite/file`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
