import React from "react";
import { login, loginAdmin, resetPassword, signUp } from "../ds/auth";
// import { signUp } from "aws-amplify/auth";

const useAuth = () => {
  const loginUser = async (data: any) => {
    const response = await login(data);
    return response;
  };
  const loginUserAdmin = async (data: any) => {
    const response = await loginAdmin(data);
    return response;
  };
  const createUser = async (data: any) => {
    const response = await signUp(data);
    return response;
  };
  const resetUserPassword = async (data: any) => {
    const response = await resetPassword(data);
    return response;
  };

  return {
    loginUser,
    createUser,
    loginUserAdmin,
    resetUserPassword,
  };
};

export default useAuth;
