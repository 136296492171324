import React from "react";
import {
  Flex,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@aws-amplify/ui-react";
// import color from "@/style/theme/colors";
// import { fontSize, fontWeight } from "@/style/theme/font";
// import { useRouter } from "next/router";

const CustomTable = ({
  headers,
  rows,
  tableTitle,
  containerWidth,
  boxShadow,
  tableButton,
  setAllChecked,
}: any) => {
  // const router = useRouter();
  return (
    <Flex
      width={containerWidth}
      borderRadius={"10px"}
      boxShadow={boxShadow || `0px 20px 25px rgba(0, 0, 0, 0.05)`}
      backgroundColor={"#FFFFFF"}
      /* margin={{ base: '0', medium: '15px', large: '0' }} */
      margin="0 auto"
      style={{ overflowX: "auto" }}
    >
      <Table className="my-custom-table">
        <TableHead width={"100%"}>
          {tableTitle ? (
            <TableRow border={"0"}>
              <TableCell
                as="th"
                color={"#141414"}
                fontWeight={500}
                fontSize={"12px"}
                // lineHeight={"22px"}
                colSpan={headers.length + 2}
              >
                {tableButton ? (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    fontSize={"12px"}
                  >
                    {tableTitle}
                    {tableButton}
                  </Flex>
                ) : (
                  tableTitle
                )}
              </TableCell>
            </TableRow>
          ) : (
            ""
          )}
          <TableRow>
            {/* <TableCell backgroundColor={"#F9FAFB"}>
              <input type="checkbox" onClick={() => setAllChecked(true)} />
            </TableCell> */}
            {headers?.map((header: any, index: any) => (
              <>
                {index === 0 ? (
                  <TableCell
                    as="th"
                    color={""}
                    height={"2.5rem"}
                    key={index}
                    fontSize={"0.875rem"}
                    colSpan={headers.length === index + 1 ? 3 : 1}
                    whiteSpace={"nowrap"}
                    backgroundColor={"#F9FAFB"}
                  >
                    <div className="flex items-center">
                      {header}
                      {/* <IoIosArrowRoundDown /> */}
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    as="th"
                    color={""}
                    height={"2.5rem"}
                    key={index}
                    fontSize={"0.875rem"}
                    colSpan={headers.length === index + 1 ? 3 : 1}
                    whiteSpace={"nowrap"}
                    backgroundColor={"#F9FAFB"}
                  >
                    {header}
                  </TableCell>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody width={"100%"} style={{ cursor: "pointer" }}>
          {rows?.map((row: any, rowIndex: any) => (
            <TableRow
              key={rowIndex}
              backgroundColor={rowIndex % 2 ? "#EAECF0" : "#FCFCFD"}
            >
              {Object.values(row).map((value: any, valueIndex: any) => {
                return (
                  <TableCell
                    lineHeight={"1rem"}
                    fontSize={"0.875rem"}
                    fontWeight={""}
                    key={`${rowIndex} ${valueIndex}`}
                    whiteSpace={"nowrap"}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Flex>
  );
};

export default CustomTable;
