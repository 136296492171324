import React, { useCallback, useEffect, useState } from "react";
import { login, signUp } from "../ds/auth";
import { getAllAdmins, getAllUsers, getDashboardStats } from "../ds/user";
// import { signUp } from "aws-amplify/auth";

const useAdmins = ({
  page,
  limit,
  search,
}: {
  page?: number;
  limit?: number;
  search?: string;
}) => {
  const [admins, setAdmins] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [dashboardReport, setDashboardReport] = useState<any>({});
  const getAdmins = useCallback(async () => {
    setLoading(true);
    const response = await getAllAdmins({
      page,
      limit,
      search,
    });
    setLoading(false);
    setAdmins(response?.data);
  }, [limit, page, search]);

  const getDashboardReport = useCallback(async () => {
    const response = await getDashboardStats();
    setDashboardReport(response);
  }, []);
  useEffect(() => {
    getAdmins();
    getDashboardReport();
  }, [getAdmins, getDashboardReport]);

  return {
    admins,
    loading,
    getAdmins,
    dashboardReport,
  };
};

export default useAdmins;
