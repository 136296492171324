import React from "react";
import SignIn from "../pages/auth/Signin";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import AdminSignin from "../pages/admin/auth/SignIn";
import AdminForgotPassword from "../pages/admin/auth/ForgotPassword";
import About from "../pages/about";
import Contact from "../pages/contact";
import DashGuard from "../route-guard/DashGuard";
import TermsOfService from "../pages/terms-of-service";
import PrivacyPolicy from "../pages/privacy-policy";
import CookiePolicy from "../pages/cookie-policy";
// import SignIn from "../pages/auth/SignIn";
// import DashGuard from "../route-guard/DashGuard";
// import ResetPassword from "../pages/auth/ResetPassword";
// import ForgotPassword from "../pages/auth/ForgotPassword";
// import CompleteSignUp from "../pages/auth/CompleteSignUp";
// import PrivacyPolicy from "../pages/privacyPolicy";
// import VerifyOtp from "../pages/auth/VerifyOtp";
// import DeactivateUser from "../pages/user/DeactivateUser";

export const getAuthRouter = () => {
  return [
    {
      path: "/",
      element: (
        <DashGuard>
          <SignIn />
        </DashGuard>
      ),

      // errorElement: <ErrorPage />,
    },
    {
      path: "/about",
      element: <About />,

      // errorElement: <ErrorPage />,
    },
    {
      path: "auth/resetpassword",
      element: <ResetPassword />,
      // errorElement: <ErrorPage />,
    },
    // {
    //   path: "complete-signup/:token",
    //   element: <CompleteSignUp />,
    //   // errorElement: <ErrorPage />,
    // },
    {
      path: "auth/forgotpassword",
      element: <ForgotPassword />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "admin/login",
      element: (
        <DashGuard>
          <AdminSignin />
        </DashGuard>
      ),
      // errorElement: <ErrorPage />,
    },
    {
      path: "admin/forgotpassword",
      element: <AdminForgotPassword />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "contact",
      element: <Contact />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfService />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
      // errorElement: <ErrorPage />,
    },
    {
      path: "/cookie-policy",
      element: <CookiePolicy />,
      // errorElement: <ErrorPage />,
    },
  ];
};
