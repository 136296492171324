import { Form, Formik } from "formik";
import React, { Dispatch, SetStateAction, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import InputText from "../shared/input/InputText";
import CustomImage from "../shared/customImage";
import DragAndDropInput from "../shared/input/DragAndDropInput";
import Avatar from "../../assets/images/png/Avatar.png";
import useUsers from "../../hooks/useUsers";
import useUploads from "../../hooks/useUploads";
import { useSelector } from "react-redux";

const ProfileDetailModal = ({
  closeModal,
  userData,
  handlePasswordModal,
}: {
  closeModal(): void;
  userData: any;
  handlePasswordModal?(): void;
}) => {
  const [loading, setLoading] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<any>([]);
  const { user } = useSelector((state: any) => state.auth);
  const { updateUserDetail } = useUsers({});
  const { uploadFiles } = useUploads();
  console.log({ user });

  const initialValues = {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    email: userData?.email || "",
    phoneNumber: userData?.phoneNumber || "",
    address: userData?.address || "",
    files: [],
  };

  const onSubmit = async (data: any) => {
    try {
      // if (requestType === "edit") {
      setLoading(true);
      let files: any;
      if (data?.files?.length > 0) {
        const formData: any = new FormData();
        data.files.map((file: any) => {
          formData.append("files", file);
        });
        files = await uploadFiles(formData);
      }
      const payload = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        address: data?.address,
        phoneNumber: data?.phoneNumber,
      };
      const res = await updateUserDetail(userData._id, {
        ...payload,
        profileImage: files?.data[0],
      });
      if (res.code === "00") {
        // toast.success("Aggregator updated");
        closeModal();
      }
      if (res.code !== "00") {
        setLoading(false);
        // toast.error(res.message || "Error updating waybill");
      }
      // }
    } catch (error) {
      // toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      setTimeout(() => {
        // getAggregators();
      }, 1000);
    }
  };

  return (
    <div className="w-[598px] h-[600px] rounded-[12px] bg-white overflow-y-auto">
      <div className="px-[24px] pt-[24px] flex  gap-[16px]">
        <div className="w-[48px] h-[48px] border border-gray-300 flex justify-center items-center rounded-[10px]">
          <FiUser />
        </div>
        <div className="flex-grow flex justify-between">
          <div>
            <h2 className="font-semibold">Profile Details</h2>
            <p className="text-[14px]">You can edit some of your details.</p>
          </div>
          <div></div>
          <AiOutlineClose
            onClick={() => closeModal()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={{}}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
            return (
              <Form
                //   style={{ height: "550px" }}
                className="w-full flex flex-fol gap-[20px]"
              >
                <div className="flex flex-col w-full bg-white p-4 rounded-[12px] pt-[20px] px-[24px]">
                  <div className="border-t border-gray-300">
                    <div className="pt-[24px]"></div>
                  </div>
                  <div className="flex flex-col gap-[16px]">
                    <div className="flex gap-[32px]">
                      <div>
                        <p className="whitespace-nowrap">Profile Image</p>
                      </div>
                      <div className="w-full flex items-center gap-[16px]">
                        <div className="h-[74px] w-[74px] rounded-full">
                          <CustomImage
                            src={
                              previewFiles.length !== 0 &&
                              userData?.profileImage
                                ? previewFiles[0]?.preview
                                : previewFiles.length !== 0 ||
                                  userData?.profileImage
                                ? previewFiles[0]?.preview ||
                                  userData?.profileImage
                                : Avatar
                            }
                            alt="avatar"
                          />
                        </div>
                        <div className="flex-grow h-[74px] border border-gray-300 py-[16px] px-[24px] flex items-center justify-center rounded-[12px]">
                          <DragAndDropInput
                            setFieldValue={setFieldValue}
                            values={values}
                            setPreviewFiles={setPreviewFiles}
                            maxFile={1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-300">
                      <div className="pt-[24px]"></div>
                    </div>
                    <InputText
                      label={"First Name"}
                      name="name"
                      error={""}
                      value={values.firstName}
                      fieldRequired={false}
                      placeholder={"Old password"}
                      readOnly={true}
                      handleChange={handleChange}
                      unit={""}
                      type={""}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                    <InputText
                      label={"Last Name"}
                      name="name"
                      error={""}
                      value={values.lastName}
                      fieldRequired={false}
                      readOnly={true}
                      placeholder={"New Password"}
                      handleChange={handleChange}
                      unit={""}
                      type={""}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                    <div className="border-t border-gray-300">
                      <div className="pt-[24px]"></div>
                    </div>
                    <InputText
                      label={"Address"}
                      name="name"
                      error={""}
                      value={values.address}
                      fieldRequired={false}
                      readOnly={true}
                      placeholder={"Enter Address"}
                      handleChange={handleChange}
                      unit={""}
                      type={""}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                    <div className="border-t border-gray-300">
                      <div className="pt-[24px]"></div>
                    </div>
                    <InputText
                      label={"Email Address"}
                      name="email"
                      error={""}
                      value={values.email}
                      fieldRequired={false}
                      readOnly={true}
                      placeholder={"Old password"}
                      handleChange={handleChange}
                      unit={""}
                      type={""}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                    <InputText
                      label={"Phone Number"}
                      name="phoneNumber"
                      error={""}
                      value={values.phoneNumber}
                      fieldRequired={false}
                      readOnly={true}
                      placeholder={"New Password"}
                      handleChange={handleChange}
                      unit={""}
                      type={""}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      setViewPassword={function (value: any): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                    <div className="border-t border-gray-300">
                      <div className="pt-[24px]"></div>
                    </div>
                  </div>
                  {user?.role === "ADMIN" || user?.role === "SUPER_ADMIN" ? (
                    <div>
                      <button
                        className="mx-auto px-2 w-[269px] h-[40px] font-normal text-xs flex justify-center items-center disabled:cursor-not-allowed border border-pfBlack md:w-272 rounded-[8px]"
                        onClick={() => {
                          if (handlePasswordModal) {
                            handlePasswordModal();
                          }
                        }}
                      >
                        Change Password
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex gap-2 justify-center pt-[32px]">
                    <div className="flex pb-[24px] w-full gap-[12px]">
                      <button
                        className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center disabled:cursor-not-allowed border border-pfBlack md:w-272 rounded-[8px]"
                        onClick={() => closeModal()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={true}
                        //   onClick={() => handleSubmit()}
                        className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
                      >
                        {loading ? "Creating..." : "Save Changes"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* <div className="flex gap-2 justify-center pt-[32px]">
        <div className="flex pb-[24px] px-[24px] w-full gap-[12px]">
          <button
            className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272 rounded-[8px]"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading ? true : false}
            //   onClick={() => handleSubmit()}
            className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
          >
            {loading ? "Creating..." : "Save Changes"}
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ProfileDetailModal;
