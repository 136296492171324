import { Form, Formik } from "formik";
import React, { Dispatch, SetStateAction, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import InputText from "../shared/input/InputText";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { changePasswordSchema } from "../../utils/validationSchema/auth";

const PasswordModal = ({ closeModal }: { closeModal(): void }) => {
  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { resetUserPassword } = useAuth();
  const { user } = useSelector((state: any) => state.auth);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await resetUserPassword({
        ...data,
        email: user?.email,
      });
      if (res.code === "00") {
        toast.success(res.message || "Password updated successfully");
        closeModal();
      }
      if (res.code !== "00") {
        setLoading(false);
        toast.error(res.message || "Error updating Password");
      }
      // }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "something went wrong");
    } finally {
      setLoading(false);
      setTimeout(() => {
        // getAggregators();
      }, 1000);
    }
  };
  return (
    <div className="w-[598px] rounded-[12px] bg-white">
      <div className="px-[24px] pt-[24px] flex  gap-[16px]">
        <div className="w-[48px] h-[48px] border border-gray-300 flex justify-center items-center rounded-[10px]">
          <FiUser />
        </div>
        <div className="flex-grow flex justify-between">
          <div>
            <h2 className="font-semibold">Password Change</h2>
            <p className="text-[14px]">You can edit your password.</p>
          </div>
          <div></div>
          <AiOutlineClose
            onClick={() => closeModal()}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={changePasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
            console.log({ errors });
            return (
              <Form
                //   style={{ height: "550px" }}
                className="w-[550px] flex flex-fol gap-[20px]"
              >
                <div className="flex flex-col w-full bg-white p-4 rounded-[12px] pt-[20px] px-[24px]">
                  <div className="flex flex-col gap-[16px]">
                    {/* <div className="flex justify-between gap-2"> */}
                    <InputText
                      label={"Old Password"}
                      name="oldPassword"
                      error={errors?.oldPassword as string}
                      value={values.oldPassword}
                      fieldRequired={false}
                      placeholder={"Old password"}
                      handleChange={handleChange}
                      unit={""}
                      type={`${viewPassword ? "text" : "password"}`}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      rightIcon
                      viewPassword={viewPassword}
                      // className={""} //   viewPassword={false}
                      setViewPassword={setViewPassword}
                    />
                    <InputText
                      label={"New Password"}
                      name="newPassword"
                      error={errors?.newPassword as string}
                      value={values.newPassword}
                      fieldRequired={false}
                      placeholder={"New Password"}
                      handleChange={handleChange}
                      unit={""}
                      type={`${viewPassword ? "text" : "password"}`}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      rightIcon
                      viewPassword={viewPassword}
                      // className={""} //   viewPassword={false}
                      setViewPassword={setViewPassword}
                    />
                    <InputText
                      label={"Repeat Password"}
                      name="repeatPassword"
                      error={errors?.repeatPassword as string}
                      value={values.repeatPassword}
                      fieldRequired={false}
                      placeholder={"Repeat password"}
                      handleChange={handleChange}
                      unit={""}
                      type={`${viewPassword ? "text" : "password"}`}
                      labelStyle="w-[160px] text-[14px]"
                      inputClassName={"w-[358px]"}
                      textStyle={"gap-[32px]"}
                      rowFormat={true}
                      rightIcon
                      viewPassword={viewPassword}
                      // className={""} //   viewPassword={false}
                      setViewPassword={setViewPassword}
                    />
                    {/* </div> */}
                  </div>

                  <div className="flex gap-2 justify-center pt-[32px]">
                    <div className="flex pb-[24px] w-full gap-[12px]">
                      <button
                        className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center disabled:cursor-not-allowed border border-pfBlack md:w-272 rounded-[8px]"
                        onClick={() => closeModal()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={loading ? true : false}
                        //   onClick={() => handleSubmit()}
                        className="bg-[#A74225] text-white flex justify-center items-center h-[40px] w-full gap-2 rounded-[8px]"
                      >
                        {loading ? "loading..." : "Save Password"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordModal;
