import { axiosInstance2 } from "../api/axios";

export const uploadFile = async (data: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance2.post(`/uploads`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const uploadOneFile = async (data: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await axiosInstance2.post(`/uploads/single`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
};
