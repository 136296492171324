import React, { useState } from "react";
import MainLayout from "../../components/shared/layouts/MainLayout";
import CustomImage from "../../components/shared/customImage";
import ConstructionImage from "../../assets/images/png/contruction-image.png";
import FeaturedIcon from "../../assets/images/png/featured-icon.png";
import ColleguesImage from "../../assets/images/png/workers-image.png";
import GroupProject from "../../assets/images/png/construction-worker-two.png";
import HouseImage from "../../assets/images/png/house-image.png";
import Toolbar from "../../assets/images/png/toolbar.png";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import FaqAccordion from "../../components/shared/accordion/FaqAccordion";
import SectionOneImageOne from "../../assets/images/png/section-one-image.png";
import SectionOneImageTwo from "../../assets/images/png/section-one-image-two.png";
import SectionOneImageThree from "../../assets/images/png/section-one-image-three.png";
const data: any = [
  {
    id: 1,
    title: "How often are construction photos updated?",
    subtitle:
      "photos are typically updated bi-weekly or at major milestones to show the progress on your home",
  },
  {
    id: 2,
    title: "How will I know when new photos are available?",
    subtitle:
      "You’ll receive an email notification whenever new updates are added",
  },
  {
    id: 3,
    title: "What stages of construction will be shown?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
  {
    id: 4,
    title: "Are the photos real-time?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
  {
    id: 5,
    title: "Can I download or share these photos?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
  {
    id: 6,
    title: "What if I notice a problem in the photos?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
  {
    id: 7,
    title: "How secure is my photo access?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
  {
    id: 8,
    title: "Who takes the photos?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
];
const About = () => {
  const [showText, setShowText] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (index: any) => {
    setShowText(index);
  };
  return (
    <MainLayout>
      <div className="flex-grow">
        <div className="flex flex-col lg:flex-row lg:px-[112px] px-[16px] pt-[88px] pb-[120px] gap-[124px]">
          <div className="flex flex-col gap-[40px] lg:w-[706px]">
            <div className="flex items-center bg-[#FAEEEA] w-[106px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
              <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                About Us
              </h2>
              <MdOutlineArrowRightAlt className=" text-[#A74225]" />
            </div>
            <h1 className="text-[48px] font-semibold">
              Welcome to Constructgraphy, the platform that brings your
              construction projects to life
            </h1>
            <p className="text-[16px] font-normal">
              with high-definition visuals. We bridge the gap between builders
              and clients by providing an organized, easy-to-navigate digital
              space where the progress of each project can be visually tracked
              and shared. Whether you’re a homeowner eager to see your dream
              home take shape or a builder dedicated to keeping clients updated,
              Constructgraphy simplifies and enhances project transparency.
            </p>
          </div>
          <div className="w-[386px]">
            <CustomImage src={ConstructionImage} alt="screen" />
          </div>
        </div>
        <div className="pt-[88px] pb-[64px] px-[16px] lg:px-[112px] flex flex-col lg:flex-row gap-[8px]">
          <div className="flex flex-col items-center gap-[88px] w-full">
            <div className="flex flex-col gap-[24px] lg:w-[726px]">
              <div className="flex flex-col items-center gap-[16px] ">
                <div className="flex items-center bg-[#FAEEEA] w-[127px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
                  <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                    Our Mission
                  </h2>
                  <MdOutlineArrowRightAlt className=" text-[#A74225]" />
                </div>
                <h1 className="text-[36px] text-center leading-[44px] font-semibold">
                  Our mission is simple
                </h1>
                <p className="text-center text-[16px] lg:w-[854px]">
                  Our mission to empower homeowners with transparency and peace
                  of mind as they watch their dream home come to life. We’re
                  committed to capturing the details, big and small, so you can
                  feel engaged and informed at every stage of construction.{" "}
                </p>
              </div>
            </div>
            <div className="w-full">
              <CustomImage src={HouseImage} alt="collegues" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[24px] lg:gap-[24px] py-[132px] lg:py-[132px] px-[16px] lg:px-[112px]  bg-[#FAEEEA]">
          <div className="flex flex-col gap-[24px]">
            <div className="flex items-center bg-[#FAEEEA] w-[127px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
              <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                Our Story
              </h2>
              <MdOutlineArrowRightAlt className=" text-[#A74225]" />
            </div>
            <p className="text-[36px] font-semibold w-full">
              Constructgraphy was born out of a passion for transparency and
              connection in the home-building journey.
            </p>
            <p className="text-[16px] font-normal lg:leading-[24px]">
              Founded on the principle of preserving memories and showcasing
              excellence, Constructgraphy bridges the gap between construction
              and visual storytelling. Our passion is to provide home builders
              and homeowners with stunning visuals that highlight their
              projects' transformation. When our founders, Emeka and Hodo, built
              their own home, they felt disconnected from the progress happening
              on-site. This experience sparked a vision: what if every homeowner
              could receive timely updates on their home’s progress from
              anywhere in the world? And so, Constructgraphy was created to make
              that vision a reality.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:items-center gap-[24px] lg:gap-[24px] py-[132px] lg:py-[132px] px-[16px] lg:px-[112px]">
          <div className="flex flex-col lg:w-[911px] lg:items-center gap-[24px]">
            <div className="flex items-center bg-[#FAEEEA] w-[186px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
              <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                What Sets Us Apart
              </h2>
              <MdOutlineArrowRightAlt className=" text-[#A74225]" />
            </div>
            <p className="text-[36px] lg:text-center font-semibold w-full">
              Constructgraphy isn’t just a photo-sharing platform
            </p>
            <p className="text-[16px] lg:text-center font-normal lg:leading-[24px]">
              it’s a seamless experience designed around your peace of mind.
              From our frequent, high-quality photo updates to our user-friendly
              platform, everything we do is centered on providing you with
              clarity and confidence. You’ll always know where things stand and
              can share updates with family and friends, making the journey a
              shared experience.
            </p>
          </div>
        </div>
        <div className="py-[120px] px-[16px] lg:px-[112px] flex flex-col lg:flex-row gap-[94px] lg:relative">
          <div className="flex flex-col gap-[24px]">
            <div className="flex flex-col gap-[24px]">
              <div className="flex flex-col gap-[16px]">
                <div className="flex items-center bg-[#FAEEEA] w-[137px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
                  <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                    Our Values
                  </h2>
                  <MdOutlineArrowRightAlt className=" text-[#A74225]" />
                </div>
                <h1 className="text-[36px] leading-[44px] font-semibold">
                  What are our values at Constructgraphy
                </h1>
              </div>
            </div>
            <div className="flex flex-col gap-[24px]">
              <div className="flex gap-[16px]">
                <div className="w-[24] h-[24px]">
                  <CustomImage src={FeaturedIcon} alt="featured icon" />
                </div>
                <div>
                  <h1 className="text-[18px] font-medium">Transparency</h1>
                  <p className="text-[16px]">
                    Keeping you informed every step of the way.
                  </p>
                </div>
              </div>
              <div className="flex gap-[16px]">
                <div className="w-[24] h-[24px]">
                  <CustomImage src={FeaturedIcon} alt="featured icon" />
                </div>
                <div>
                  <h1 className="text-[18px] font-medium">Quality</h1>
                  <p className="text-[16px]">
                    Delivering high-resolution, professional photos you can rely
                    on.
                  </p>
                </div>
              </div>
              <div className="flex gap-[16px]">
                <div className="w-[24] h-[24px]">
                  <CustomImage src={FeaturedIcon} alt="featured icon" />
                </div>
                <div>
                  <h1 className="text-[18px] font-medium">
                    Customer-Centricity
                  </h1>
                  <p className="text-[16px]">
                    Ensuring that you’re at the heart of every update and
                    interaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[568px] lg:h-[614px]">
            <CustomImage src={ColleguesImage} alt="collegues" />
          </div>
          <div className="hidden lg:block absolute bottom-[80px] left-[720px] lg:w-[318px] lg:h-[318px]">
            <CustomImage src={Toolbar} alt="collegues" />
          </div>
        </div>
        <div className="flex flex-col lg:items-center gap-[88px] lg:gap-[24px] py-[56px] lg:py-[120px] px-[16px] lg:px-[112px]">
          <div className="flex flex-col lg:w-[911px] lg:items-center gap-[24px]">
            <div className="flex items-center bg-[#FAEEEA] w-[256px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
              <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                How Constructgraphy works
              </h2>
              <MdOutlineArrowRightAlt className=" text-[#A74225]" />
            </div>
            <p className="text-[36px] lg:text-center font-semibold w-full">
              Constructgraphy Simplifies Your ProjectManagement.
            </p>
            <p className="text-[16px] lg:text-center font-normal lg:leading-[24px]">
              Our team captures high-quality photos at key stages of
              construction and uploads them to your personal account. All you
              need to do is log in to see the latest updates. You’ll receive
              notifications whenever new photos are available, making it easy to
              stay connected.
            </p>
          </div>
          <div className="flex flex-col lg:flex-row gap-[48px]">
            <div className="flex flex-col gap-[24px]">
              <CustomImage src={SectionOneImageOne} alt="" />
              <p className="text-[20px] text-center font-bold leading-[20px] text-[#8A456F]">
                Captures high-quality photos at key stages
              </p>
            </div>
            <div className="flex flex-col gap-[24px]">
              <CustomImage src={SectionOneImageTwo} alt="" />
              <p className="text-[20px] text-center font-bold leading-[20px] text-[#8A456F]">
                Uploads them to your personal account
              </p>
            </div>
            <div className="flex flex-col gap-[24px]">
              <CustomImage src={SectionOneImageThree} alt="" />
              <p className="text-[20px] text-center font-bold leading-[20px] text-[#8A456F]">
                Receive notifications whenever new photos
              </p>
            </div>
          </div>
        </div>
        <div className="pt-[88px] pb-[64px] px-[16px] lg:px-[112px] flex flex-col lg:flex-row gap-[8px]">
          <div className="flex flex-col items-center gap-[88px] w-full">
            <div className="flex flex-col gap-[24px] lg:w-[726px]">
              <div className="flex flex-col items-center gap-[16px] ">
                <div className="flex items-center bg-[#FAEEEA] w-[75px] gap-[4px] rounded-[8px] py-[4px] pl-[10px] pr-[8px] border border-[#EDBAAB]">
                  <h2 className="text-[16px]  font-semibold text-[#A74225] whitespace-nowrap">
                    FAQs
                  </h2>
                  <MdOutlineArrowRightAlt className=" text-[#A74225]" />
                </div>
                <h1 className="text-[36px] text-center leading-[44px] font-semibold">
                  Explore Our Frequently Asked Questions for Quick Answers and
                  Support.
                </h1>
              </div>
            </div>
            <div className="w-full">
              <CustomImage src={GroupProject} alt="collegues" />
            </div>
            <div className="w-full">
              <FaqAccordion
                faqs={data}
                handleClick={handleClick}
                showText={showText}
                setOpen={setOpen}
                open={open}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default About;
