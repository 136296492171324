import React, { useState } from "react";
import { Form, Formik } from "formik";

// import CustomImage from "../../components/shared/customImage";
// import BuilderImage from "../../assets/images/png/builder-image.png";
// import Logo from "../../assets/images/png/mobile-logo.png";
import InputText from "../../../components/shared/input/InputText";
import AdminAuthLayout from "../../../components/shared/layouts/AdminAuthLayout";
import useAuth from "../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../feature/auth";
import { Link, useNavigate } from "react-router-dom";
import { signInSchema } from "../../../utils/validationSchema/auth";
import toast from "react-hot-toast";

const AdminSignin = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { loginUserAdmin } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };
  const signIn = async (data: any) => {
    try {
      setLoading(true);
      const res = await loginUserAdmin(data);
      if (res?.data) {
        const { user } = res?.data;
        localStorage.setItem("accessToken", res?.data?.access_token);
        dispatch(setUserData(user));
        navigate("/admin/dashboard");
      }
      setError(res.message);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message || "something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <AdminAuthLayout>
      <div className="flex-grow flex flex-col  lg:flex-col lg:flex-grow lg:items-center lg:justify-center lg: lg:w-full gap-[32px] lg:gap-[88px] px-[16px] lg:px-[64px]">
        <div className="flex flex-col justify-center gap-[24px] lg:w-[411px]">
          <div>
            <h1 className="font-bold text-[24px]">Welcome</h1>
            <p>Please enter your details.</p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={signInSchema}
              onSubmit={signIn}
            >
              {({ handleChange, errors, values }) => {
                return (
                  <Form>
                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your email"}
                        label={"Email"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.email}
                        name={"email"}
                        unit={""}
                        type={"text"}
                        inputClassName={"w-full h-[44px]"}
                        error={errors.email}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        viewPassword={false}
                        // className={""}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>

                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your password"}
                        label={"Password"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.password}
                        name={"password"}
                        unit={""}
                        type={`${viewPassword ? "text" : "password"}`}
                        inputClassName={"w-full h-[44px]"}
                        error={errors.password}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        rightIcon
                        viewPassword={viewPassword}
                        // className={""} //   viewPassword={false}
                        setViewPassword={setViewPassword}
                      />
                    </div>
                    <div
                      className="mt-4 mb-[32px] flex justify-end gap-1 w-full"
                      //   onClick={() => navigate("/forgotpassword")}
                      style={{ cursor: "pointer" }}
                    >
                      <Link
                        to={"/admin/forgotpassword"}
                        className="mb-0 text-sm text-[#A74225]"
                      >
                        Forgot your password?
                      </Link>
                    </div>
                    <div className="mt-2 w-full">
                      <button
                        className="bg-[#A74225] rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                        type="submit"
                      >
                        {loading ? "loading..." : "Access your gallery"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </AdminAuthLayout>
  );
};

export default AdminSignin;
