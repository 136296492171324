import React from "react";

const CustomButton = ({
  label,
  className,
  handleClick,
}: {
  label: string;
  className: string;
  handleClick: () => void;
}) => {
  return (
    <>
      <button className={className} onClick={() => handleClick()}>
        {label}
      </button>
    </>
  );
};

export default CustomButton;
