import React from "react";
import AuthLayout from "../../components/shared/layouts/AuthLayout";
import MainLayout from "../../components/shared/layouts/MainLayout";

const TermsOfService = () => {
  return (
    <MainLayout>
      <div className="px-[64px] py-[96px] flex flex-col gap-[20px]">
        <div className="flex flex-col gap-[32px]">
          <p className="text-center text-[18px]">Current as of 11/28/2024</p>
          <h1 className="text-center text-[48px] font-semibold">
            Constructgraphy Terms of Service
          </h1>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Introduction:</h1>
            <p>
              Constructgraphy (“we”, “us”, or “our”;) provides construction
              photography services and operates the website
              [www.constructgraphy.com] “Site”. These Terms of Service “Terms”
              govern your access to and use of the Site, services, and content.
            </p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Definitions:</h1>
            <p>
              “Client” means any individual or entity that engages our services.
            </p>
            <p>“Content” includes images, videos, and other media.</p>
            <p>
              “Services” includes construction photography, aerial photography,
              time-lapse photography, and related services.
            </p>
            <p>“Site” refers to our website and all its features.</p>
            <p>“User” means any individual accessing the Site.</p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">Terms of Use</h1>
            <p>
              1. Acceptance: By using the Site or Services, you agree to these
              Terms.
            </p>
            <p>
              2. Age Restriction: You must be at least 18 years old to use our
              Services.
            </p>
            <p>
              3. Intellectual Property: Constructgraphy retains ownership of all
              Content.
            </p>
            <p>
              4. License Grant: Clients receive a non-exclusive license to use
              Content for agreed-upon purposes.
            </p>
            <p>5. Prohibited Use: You shall not:</p>
            <p>- Use Content for unauthorised purposes.</p>
            <p>- Resell or distribute Content.</p>
            <p>- Remove watermarks or copyright notices.</p>
            <p>
              6. Payment Terms: Clients shall pay fees as outlined in our
              agreement.
            </p>
            <p>7. Cancellation Policy: [Insert cancellation policy].</p>
            <p>8. Warranty Disclaimer: We disclaim warranties of any kind.</p>
            <p>
              9. Limitation of Liability: Our liability is limited to [insert
              amount].
            </p>
            <p>
              10. Indemnification: You agree to indemnify us against claims,
              damages, and expenses.
            </p>
          </div>
        </div>
        <div className="px-[32px] flex flex-col gap-[64px]">
          <div className="flex flex-col gap-[20px]">
            <h1 className="text-[30px] font-medium">
              Can I opt-in and opt-out?
            </h1>
            <p>1. Provide accurate project information.</p>
            <p>2. Ensure site safety and accessibility.</p>
            <p>3. Obtain necessary permits and approvals.</p>
            <p>Constructgraphy Responsibilities:</p>
            <p>1. Deliver high-quality Services.</p>
            <p>2. Maintain confidentiality.</p>
            <p>3. Comply with applicable laws.</p>
            <p>
              Governing Law: These Terms shall be governed by and construed in
              accordance with
            </p>
            <p>the laws of Alberta and Canada.</p>
            <p>
              Dispute Resolution: Any disputes shall be resolved through [insert
              dispute resolution process]
            </p>
            <p>
              Changes to Terms: We reserve the right to modify these Terms at
              any time.
            </p>
            <p>
              Contact Us: For questions or concerns, please contact:
              help@constructgraphy.com
            </p>
            <p>
              Acceptance: By using the Site or Services, you acknowledge that
              you have read, understood, and agree to these Terms.
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default TermsOfService;
