import React from "react";
import CustomImage from "../shared/customImage";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineChevronRight } from "react-icons/md";
import { MdOutlineChevronLeft } from "react-icons/md";
import { MdRefresh } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { CiCircleInfo } from "react-icons/ci";
import { IoMdShareAlt } from "react-icons/io";
import { FiChevronDown } from "react-icons/fi";
import { TfiDownload } from "react-icons/tfi";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";
import CustomVideo from "../shared/customVideo";

const ViewMedia = ({ closeModal, data }: any) => {
  const handleDownload = (url: string, fileName: string) => {
    fetch(url, { mode: "no-cors", cache: "no-cache" })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        console.log({ url });
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };
  console.log({ data });
  const checkString = (str: any) =>
    ["video/mp4", "video/gif"].some((word) => str.includes(word));
  return (
    <div className="w-[370px] lg:w-[700px]">
      <div className="flex justify-between pb-[10px] w-full px-[16px] lg:px-0">
        <div>
          <p className="text-white">{data?.name}</p>
        </div>
        <div className="">
          <IoCloseOutline
            style={{ width: 26, height: 26, cursor: "pointer", color: "white" }}
            onClick={() => closeModal()}
          />
        </div>
      </div>
      {checkString(data?.type) ? (
        <div className="w-full h-[500px] lg:w-full lg:h-[591px] px-[16px] lg:px-0">
          <CustomVideo src={data?.mediaUrl} alt={data?.name} />
        </div>
      ) : (
        <div className="w-full h-[500px] lg:w-full lg:h-[591px] px-[16px] lg:px-0">
          <CustomImage src={data?.mediaUrl} alt={data?.name} />
        </div>
      )}
      <div className="flex flex-col gap-[20px] lg:gap-0 lg:flex-row lg:justify-between pt-[10px] px-[16px] lg:px-0">
        <div className="hidden lg:block p-[12px] bg-white h-[44px]">
          <MdOutlineChevronLeft />
        </div>
        <div className="lg:hidden p-[12px] flex justify-between gap-[8px] bg-transparent border border-[#98A2B3] h-[44px]">
          <AiOutlineMinus style={{ color: "white" }} />
          <GoPlus style={{ color: "white" }} />
        </div>
        <div className="flex gap-[16px]">
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            <MdRefresh style={{ color: "white" }} />
          </div>
          <div className="w-[148px] hidden lg:block">
            <div className="w-full flex justify-between items-center  p-[16px] gap-[8px] lg:p-[12px] border border-[#98A2B3] h-[44px]">
              <AiOutlineMinus style={{ color: "white" }} />
              <GoPlus style={{ color: "white" }} />
            </div>
          </div>
          <div
            className="flex items-center p-[16px] w-[72px] gap-[8px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px] cursor-pointer"
            onClick={() => handleDownload(data?.mediaUrl, data?.name)}
          >
            <TfiDownload style={{ color: "white" }} />
            <FiChevronDown style={{ color: "white" }} />
          </div>
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            <AiOutlineHeart style={{ color: "white" }} />
          </div>
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            <CiCircleInfo style={{ color: "white" }} />
          </div>
          <div className="p-[16px] lg:p-[12px] bg-transparent border border-[#98A2B3] h-[44px]">
            <IoMdShareAlt style={{ color: "white" }} />
          </div>
        </div>
        <div className="hidden lg:block p-[12px] bg-white h-[44px]">
          <MdOutlineChevronRight />
        </div>
        <div className="flex justify-center gap-[8px] lg:hidden">
          <div className="p-[12px] bg-white">
            <MdOutlineChevronLeft />
          </div>
          <div className="p-[12px] bg-white">
            <MdOutlineChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMedia;
