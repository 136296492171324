import React, { useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const DashGuard = ({ children }: { children: React.ReactElement }) => {
  const { islogin, user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (islogin && accessToken) {
      switch (user?.role) {
        case "SUPER_ADMIN":
          navigate("/admin/dashboard");
          break;
        case "ADMIN":
          navigate("/admin/dashboard");
          break;
        case "BUILDER":
          navigate("/dashboard");
          break;
        case "OWNER":
          navigate("/dashboard");
          break;
        default:
          navigate("/dashboard");
        //do nothing
      }
    }
  }, [accessToken, islogin, navigate, user]);

  return <>{children}</>;
};

export default DashGuard;
