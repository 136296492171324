import React, { useRef, useState } from "react";
import FileCard from "../../../components/shared/cards/FileCard";
import useProjects from "../../../hooks/useProject";
import { useParams } from "react-router-dom";
import { TiFolder } from "react-icons/ti";
import { MdArrowForwardIos } from "react-icons/md";
import { MdOutlineChevronRight } from "react-icons/md";
import Modal from "../../../components/shared/modalWrapper/Modal";
import ViewMedia from "../../../components/modal/ViewMedia";
import useFolders from "../../../hooks/useFolders";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { formatBytes } from "../../../utils";
import moment from "moment";
import { MdFilterList } from "react-icons/md";
import UploadImageModal from "../../../components/modal/UploadImageModal";
import { GoPlus } from "react-icons/go";

const AdminProjectDetail = () => {
  const wrapperRef = useRef(null);
  const { id } = useParams();
  const [viewFile, setViewFile] = useOutsideClick(wrapperRef);
  const [uploadImage, setUploadImage] = useOutsideClick(wrapperRef);
  const [file, setFile] = useState();
  const { folder, getFolderById } = useFolders({ id: id });
  return (
    <div className=" flex flex-col items-center gap-[32px] ">
      <div className="flex flex-col gap-[12px] w-full py-[24px] px-[16px] lg:px-[24px] lg:py-[24px]">
        <div>
          <div className="flex items-center gap-[8px]">
            <TiFolder className="w-[28px] h-[28px]" />
            <MdOutlineChevronRight className="w-[16px] h-[16px]" />
            <h1 className="text-[14px] font-semibold">
              {folder?.data?.folderName}
            </h1>
          </div>
        </div>
        <h1 className="text-[30px] font-semibold">
          {folder?.data?.folderName}
        </h1>
        <div className="flex flex-col gap-y-[12px]">
          <p className="underline text-[#2E90FA]">
            {folder?.data?.owner?.address}
          </p>
          <p>
            Created {moment(folder?.data?.createdAt).format("MMMM DD, YYYY")}
          </p>
          <p>
            {" "}
            {formatBytes(
              folder?.data?.files?.reduce(
                (prev: any, curr: any) => prev + curr.size,
                0
              )
            )}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-bewteen py-[24px] px-[16px] lg:px-[24px] lg:py-[24px]">
        <div className="w-full">
          <p className="text-[14px] font-semibold">Files</p>
          <p className="text-[12px]">20 Files</p>
        </div>
        <div className="flex gap-[12px]">
          <button
            className="flex gap-[8px] h-[40px] items-center border border-gray-300 py-[10px] px-[16px] capitalize"
            // onClick={() => setShowFilter((prev) => !prev)}
          >
            <MdFilterList />
            Filters
          </button>
          <button
            className="flex gap-[8px] whitespace-nowrap h-[40px] items-center border border-gray-300 py-[10px] px-[16px] capitalize"
            onClick={() => setUploadImage(true)}
          >
            <GoPlus />
            Add media
          </button>
        </div>
        <div className="pt-[24px]">
          <div className=" border-t border-gray-300"></div>
        </div>
      </div>
      <div className="flex flex-col items-center px-[24px] lg:flex-row lg:flex-wrap gap-[16px] pb-[72px] w-full">
        {folder?.data?.files.map((data: any, index: number) => {
          return (
            <FileCard
              data={data}
              handleClick={() => {
                setViewFile(true);
                setFile(data);
              }}
              favourite={false}
              key={`${index} + ${data._id}`}
            />
          );
        })}
      </div>
      {viewFile && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewFile(false)}
        >
          <ViewMedia
            closeModal={() => setViewFile(false)}
            data={file}
            // getAllData={getUsers}
          />
        </Modal>
      )}

      {uploadImage && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setUploadImage(false)}
        >
          <UploadImageModal
            closeModal={() => setUploadImage(false)}
            data={[]}
            getData={getFolderById}
            userData={{
              id: id,
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdminProjectDetail;
