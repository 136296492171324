import React from "react";
import AccordionWrapper from "./AccordionWrapper";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const FaqAccordion = ({ faqs, handleClick, showText, setOpen, open }: any) => {
  return (
    <AccordionWrapper>
      {faqs.map(({ id, title, subtitle }: any, index: number) => {
        return (
          <AccordionItem value={`${id}`} className="w-full  mb-3" key={index}>
            <AccordionTrigger
              className="flex justify-between items-center w-full"
              onClick={() => {
                handleClick(index);
                setOpen((prev: any) => !prev);
              }}
            >
              {title}
              {!open && showText === index ? (
                <AiOutlineMinus className="AccordionChevron" />
              ) : (
                <AiOutlinePlus className="AccordionChevron" />
              )}
            </AccordionTrigger>
            <AccordionContent className="--radix-collapsible-content-width: 322px;">
              {subtitle}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </AccordionWrapper>
  );
};

export default FaqAccordion;
