import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/auth";

const AuthGuard = ({ children }: { children: React.ReactElement }) => {
  const { islogin, user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!islogin || !accessToken) {
      navigate("/");
      //   switch (user?.role) {
      //     case "SUPER_ADMIN":
      //       console.log("here...");
      //       navigate("/admin/login");
      //       break;
      //     case "ADMIN":
      //       console.log("here...");
      //       navigate("/admin/login");
      //       break;
      //     case "BUILDER":
      //       console.log("here...");
      //       navigate("/");
      //       break;
      //     case "OWNER":
      //       console.log("here...");
      //       break;
      //     default:
      //     // do nothing
      //   }
    }
  }, [accessToken, islogin, navigate, user?.role]);

  return <>{children}</>;
};

export default AuthGuard;
