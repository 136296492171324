import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  islogin: false,
  isVerify: false,
  // acceptedTos: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
      state.islogin = true;
    },
    // setTos: (state) => {
    //   state.acceptedTos = true;
    // },
    setLogin: (state, action) => {
      state.isVerify = action.payload;
    },
    // shouldAddOfflineModels: (state, action) => {
    //   state.addOfflineModels = action.payload ?? !state.addOfflineModels;
    // },
    logout: (state) => initialState,
  },
});
// Action creators are generated for each case reducer function
export const {
  setUserData,
  // setTos,
  logout,
  //   setVerify,
  setLogin,
  //   shouldAddOfflineModels,
} = authSlice.actions;

export default authSlice.reducer;
