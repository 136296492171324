import React from "react";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import CustomImage from "../customImage";
import FooterLogo from "../../../assets/images/png/mobile-logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="flex flex-col gap-[40px] py-[32px] lg:py-[32px] px-[16px] lg:px-[64px] ">
      <div className="flex flex-col gap-[48px]">
        <div className="w-[206px]">
          <CustomImage src={FooterLogo} alt="footer logo" />
        </div>
        <div>
          <ul className="flex gap-[16px] flex-wrap">
            <Link to={"#"} className="text-[16px] font-normal">
              FAQs
            </Link>
            <Link to={"#"} className="text-[16px] whitespace-nowrap">
              Help Center
            </Link>
            <Link
              to={"/privacy-policy"}
              className="text-[16px] whitespace-nowrap"
            >
              Privacy Policy
            </Link>
            <Link
              to={"/terms-of-service"}
              className="text-[16px] whitespace-nowrap"
            >
              Terms of service
            </Link>
            <Link
              to={"/cookie-policy"}
              className="text-[16px] whitespace-nowrap"
            >
              Cookie Settings
            </Link>
          </ul>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-between pt-[32px] gap-[32px] border-t border-gray-300">
        <div className="text-[16px]">
          © 2024 Constructgraphy. All rights reserved.
        </div>
        <div>
          <ul className="flex gap-[24px]">
            <li>
              <FaTwitter className="w-[24px] h-[24px]" />
            </li>
            <li>
              <FaLinkedin className="w-[24px] h-[24px]" />
            </li>
            <li>
              <FaFacebook className="w-[24px] h-[24px]" />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
