"use client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./Accordion";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const cards = ["long_card", "long_card1", "long_card2"];
export default function AccordionWrapper({ children }: any) {
  return (
    <>
      <Accordion
        defaultValue={"1"}
        type="single"
        collapsible
        className="flex gap-[32px] flex-col w-full"
      >
        {children}
      </Accordion>
    </>
  );
}

const ValidSectionIcon = ({ index }: any) => {
  return (
    <div className="w-[24px] h-[24px] rounded-full flex justify-center items-center bg-blue-400">
      {index}
    </div>
  );
};

const InvalidSectionIcon = ({ index }: any) => {
  return (
    <div className="w-[24px] h-[24px] rounded-full flex justify-center items-center bg-gray-300">
      {index}
    </div>
  );
};
