import React, { useRef, useState } from "react";
import CustomTable from "../../../components/shared/table/CustomTable";
import { CiCirclePlus } from "react-icons/ci";
import { MdFilterList } from "react-icons/md";
import DateInput from "../../../components/shared/DateInput";
import { FiEdit2 } from "react-icons/fi";
import Modal from "../../../components/shared/modalWrapper/Modal";
import AdminModal from "../../../components/modal/AdminModal";
import useOutsideClick from "../../../hooks/useOutsideClick";
import useAdmins from "../../../hooks/useAdmin";
import SearchableDropdown2 from "../../../components/shared/input/SearchableDropdown2";
import InputSearch from "../../../components/shared/input/InputSearch";
import { userRole } from "../../../utils";
import AdminDetail from "../../../components/modal/AdminDetail";
import moment from "moment";
import PaginationTab from "../../../components/shared/pagination/PaginationTab";
import AdminManagementFooter from "../../../components/shared/footer/AdminManagementFooter";
import useUsers from "../../../hooks/useUsers";

const headers = [
  "Full Name",
  "Email Address",
  "Phone Number",
  "Role",
  "Created Date",
];

const AdminManagement = () => {
  const wrapperRef = useRef(null);
  const [createAdmin, setCreateAdmin] = useOutsideClick(wrapperRef);
  const [editAdminDetail, setEditAdminDetail] = useOutsideClick(wrapperRef);
  const [showAdminDetail, setShowAdminDetail] = useOutsideClick(wrapperRef);
  const [adminDetail, setAdminDetail] = useState<any>({});
  const [products, setProducts] = useState([1, 1]);
  const [role, setRole] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const { admins, loading, getAdmins } = useAdmins({
    page,
    limit,
    search: query,
  });
  const { deleteUserDetail } = useUsers({});

  const handlePageChange = (data: any) => {
    if (data === 0) {
      setPage(1);
    }
    setPage(data + 1);
  };

  const nextPage = () => {
    if (page === admins?.totalPages) {
      setPage(page);
    }
    if (page < admins?.totalPages) {
      setPage((prev) =>
        page >= 1 && page !== admins?.totalPages ? prev + 1 : prev
      );
    }
    // getAllActiveFo(query, selectedState, selectedLga);
  };
  const prevPage = () => {
    if (page === admins?.totalPages) {
      setPage(1);
    }
    if (page !== 0) {
      setPage((prev) =>
        page >= 1 || page === admins?.totalPages ? prev - 1 : prev
      );
    }
    // getAllActiveFo(query, selectedState, selectedLga);
  };

  return (
    <div className="flex flex-col gap-[8px] pt-[32px] pr-[32px] pb-[8px] pl-[24px]">
      <div className="flex flex-col gap-[16px] pb-[32px]">
        <h1 className="font-semibold text-[24px]">Admin Management</h1>
        <p className="text-[16px]">Manage all admins.</p>
      </div>
      <div className="border border-gray-300 rounded-[8px]">
        <div className="lg:gap-0 lg:flex-row lg:justify-between pt-[16px] pb-[24px] ">
          <div className="flex items-center gap-[12px] py-[12px] px-[16px]">
            <InputSearch
              placeholder="Search"
              inputValue={query}
              setInputValue={setQuery}
            />
            <div className="flex gap-[12px]">
              <button
                className="flex w-[181px] text-[14px] text-white gap-[8px] h-[44px] bg-[#A74225] items-center border border-gray-300 py-[10px] px-[16px] capitalize rounded-[8px]"
                onClick={() => setCreateAdmin(true)}
              >
                <CiCirclePlus />
                Create new admin
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center">
            <p className="text-center">Loading...</p>
          </div>
        ) : admins?.length === 0 ? (
          <div className="flex justify-center">
            <p className="text-center">No data.</p>
          </div>
        ) : (
          <CustomTable
            headers={headers}
            rows={admins.map((data: any, index: number) => {
              return {
                fullName: `${data?.firstName} ${data.lastName}`,
                email: data.email,
                phoneNumber: data.phoneNumber,
                role: (
                  <span
                    className={`${
                      data?.role === "BUILDER"
                        ? "bg-[#EFF8FF] border-[#175CD3]"
                        : data?.role === "OWNER"
                        ? "bg-[#FDF2FA] border-[#C11574]"
                        : data?.role === "SUPER_ADMIN"
                        ? "bg-[#FEF6EE] border-[#B93815]"
                        : data?.role === "ADMIN"
                        ? "bg-[#F9FAFB] border-[#344054]"
                        : ""
                    } rounded-[16px] w-max flex items-center gap-[4px] py-[2px] pr-[8px] pl-[6px]`}
                  >
                    <span
                      className={`${
                        data?.role === "BUILDER"
                          ? "bg-[#175CD3]"
                          : data?.role === "OWNER"
                          ? "bg-[#C11574]"
                          : data?.role === "SUPER_ADMIN"
                          ? "bg-[#B93815]"
                          : data?.role === "ADMIN"
                          ? "bg-[#344054]"
                          : ""
                      } w-[6px] h-[6px] rounded-full inline-block`}
                    ></span>
                    <span
                      className={`${
                        data?.role === "BUILDER"
                          ? "text-[#175CD3]"
                          : data?.role === "OWNER"
                          ? "text-[#C11574]"
                          : data?.role === "SUPER_ADMIN"
                          ? "text-[#B93815]"
                          : data?.role === "ADMIN"
                          ? "text-[#344054]"
                          : ""
                      } text-[12px] capitalize`}
                    >
                      {userRole(data?.role)}
                    </span>
                  </span>
                ),
                date: moment(data?.createdAt).format("MMMM DD, YYYY | HH:MM A"),
                open: (
                  <FiEdit2
                    key={index}
                    className="w-[20px] h-[20px]"
                    onClick={() => {
                      setShowAdminDetail(true);
                      setAdminDetail(data);
                    }}
                  />
                ),
              };
            })}
            tableTitle={undefined}
            containerWidth={undefined}
            boxShadow={undefined}
            tableButton={undefined}
            setAllChecked={undefined}
          />
        )}
        <div className="pt-[20px] px-[24px] pb-[24px]">
          <PaginationTab
            pageIndex={page}
            pageCount={admins?.totalPages}
            gotoPage={handlePageChange}
            // pageSize={10}
            canPreviousPage={""}
            canNextPage={""}
            handleNext={() => nextPage()}
            handlePrevious={() => prevPage()}
            // pageOptions,
            setPageSize={""}
            // useAlternatePagination={false}
          />
        </div>
      </div>
      <AdminManagementFooter />
      {createAdmin && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setCreateAdmin(false)}
        >
          <AdminModal
            closeModal={() => setCreateAdmin(false)}
            getAllData={getAdmins}
          />
        </Modal>
      )}
      {editAdminDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditAdminDetail(false)}
        >
          <AdminModal
            closeModal={() => setEditAdminDetail(false)}
            requestType={"edit"}
            getAllData={getAdmins}
            userData={adminDetail}
          />
        </Modal>
      )}
      {showAdminDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowAdminDetail(false)}
        >
          <AdminDetail
            closeModal={() => setShowAdminDetail(false)}
            setEditDetail={() => setEditAdminDetail(true)}
            // setEditDetail={() => setEditAdminDetail(true)}
            data={adminDetail}
            handleDelete={() => {
              deleteUserDetail(adminDetail?._id);
              getAdmins();
              setShowAdminDetail(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdminManagement;
