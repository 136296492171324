import React from "react";
import { AiOutlineHeart, AiOutlineMore } from "react-icons/ai";
import CustomImage from "../customImage";
import Home from "../../../assets/images/png/home.png";
import { formatBytes } from "../../../utils";
import moment from "moment";
import { FaHeart } from "react-icons/fa";

const FileCard = ({
  data,
  handleClick,
  handleFavourite,
  favourite,
}: {
  data: any;
  handleClick?: () => void;
  handleFavourite?: () => void;
  favourite: boolean;
}) => {
  return (
    <div
      className="flex flex-col items-center w-[180.96px] h-[152.59px] lg:w-[316px] lg:h-[243px] rounded-[12px] border border-gray-300 cursor-pointer"
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      <div className="p-[16px] flex justify-between w-full">
        <p className=" text-[12px]">{data?.name}</p>
        <div className="flex gap-[8px]">
          {favourite ? (
            <FaHeart
              className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
              style={{ color: "red" }}
              onClick={() => {
                if (handleFavourite) {
                  handleFavourite();
                }
              }}
            />
          ) : (
            <AiOutlineHeart
              className={`w-[9.78px] h-[9.78px] lg:w-[20px] lg:h-[20px]`}
              style={{ backgroundColor: `${favourite ? "red" : ""}` }}
              onClick={() => {
                if (handleFavourite) {
                  handleFavourite();
                }
              }}
            />
          )}
          <AiOutlineMore className="w-[20px] h-[20px]" />
        </div>
      </div>
      <div>
        <div className="w-[165.34px] h-[68.78px] lg:w-[284px] lg:h-[141px]">
          <CustomImage src={data?.mediaUrl} alt="home" />
        </div>
      </div>
      <div className="p-[16px] flex justify-between w-full">
        <p className="text-[5.87px] lg:text-[12px]">{formatBytes(data.size)}</p>
        <p className="whitespace-nowrap text-[5.87px] lg:text-[12px]">
          Uploaded {moment(data?.updatedAt).format("MMM DD, YYYY")}
        </p>
      </div>
    </div>
  );
};

export default FileCard;
