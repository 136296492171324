import React, { useCallback, useEffect, useState } from "react";
import { login, signUp } from "../ds/auth";
import { getAllUsers } from "../ds/user";
import {
  createFolder,
  getAllFolders,
  getOneFolder,
  updateFolderFiles,
} from "../ds/folder";
// import { signUp } from "aws-amplify/auth";

const useFolders = ({
  owner = "",
  builder = "",
  page,
  limit,
  startDate = "",
  endDate = "",
  id,
}: {
  owner?: string;
  builder?: string;
  page?: number;
  limit?: number;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  id?: string;
}) => {
  const [folders, setFolders] = useState<any>([]);
  const [totalMedia, setTotalMedia] = useState<{ [x: string]: string }[]>([]);
  const [folder, setFolder] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const getFolders = useCallback(async () => {
    setLoading(true);
    const response = await getAllFolders({
      owner: owner,
      builder: builder,
      page: page,
      limit: limit,
      startDate,
      endDate,
    });
    setLoading(false);
    setFolders(response?.data);
    setTotalMedia(response?.totalMedia);
  }, [builder, endDate, limit, owner, page, startDate]);

  const getFolderById = useCallback(async () => {
    if (id) {
      const response = await getOneFolder({ id });
      setFolder(response);
    }
  }, [id]);
  const updateFolderFilesById = async (id: string, filedata: any) => {
    const response = await updateFolderFiles({ id, data: filedata });
    return response;
  };

  useEffect(() => {
    getFolders();
    getFolderById();
  }, [getFolders]);

  const createFolders = async (data: any) => {
    const response = await createFolder(data);
    return response;
  };

  return {
    totalMedia,
    folders,
    loading,
    createFolders,
    getFolders,
    getFolderById,
    folder,
    updateFolderFilesById,
  };
};

export default useFolders;
