import React, { useState } from "react";
import { Form, Formik } from "formik";

// import Header from "../../components/shared/header/AuthHeader";
// import Footer from "../../components/shared/footer";
// import CustomImage from "../../components/shared/customImage";
import BuilderImage from "../../assets/images/png/builder-image.png";
import Logo from "../../assets/images/png/mobile-logo.png";
import InputText from "../../../components/shared/input/InputText";
import { Link } from "react-router-dom";
import AdminAuthLayout from "../../../components/shared/layouts/AdminAuthLayout";

const AdminForgotPassword = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };
  const signIn = async (data: any) => {
    //   try {
    //     setLoading(true);
    //     const res = await loginUser(data);
    //     if (res?.data) {
    //       localStorage.setItem("accessToken", res?.data?.access_token);
    //       const { user } = res?.data;
    //       dispatch(setUser(user));
    //       navigate("/dashboard");
    //     }
    //     setError(res.message);
    //   } catch (error) {
    //   } finally {
    //     setLoading(false);
    //   }
  };
  return (
    <AdminAuthLayout>
      <div className="flex-grow flex flex-col  lg:flex-col lg:flex-grow lg:items-center lg:justify-center lg: lg:w-full gap-[32px] lg:gap-[88px] px-[16px] lg:px-[64px]">
        <div className="flex flex-col justify-center gap-[24px] lg:w-[411px]">
          <div>
            <h1 className="font-bold text-[24px]">Forgot password</h1>
            <p className="text-[14px] font-normal">
              Provide the detail(s) below to regain access.
            </p>
          </div>
          <div className="w-full">
            <Formik
              initialValues={initialValues}
              validationSchema={{}}
              onSubmit={signIn}
            >
              {({ handleChange, errors, values }) => {
                return (
                  <Form>
                    {/* <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your full name"}
                        label={"Full name"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.email}
                        name={"name"}
                        unit={""}
                        type={"text"}
                        //   inputClassName={"w-full h-[44px]"}
                        error={errors.email}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        viewPassword={false}
                        className={""}
                      />
                    </div> */}

                    <div className="mb-3 w-full">
                      <InputText
                        placeholder={"Enter your email"}
                        label={"Email"}
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleChange(e)}
                        value={values.password}
                        name={"email"}
                        unit={""}
                        type={"text"}
                        inputClassName={"w-full h-[44px]"}
                        error={errors.password}
                        fieldRequired={false}
                        readOnly={false}
                        disabled={false}
                        textStyle={""}
                        setViewPassword={function (value: any): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                    </div>
                    <div className="mt-[24px] w-full">
                      <button
                        className="bg-[#A74225] rounded-[8px] w-full flex justify-center items-center p-4 text-white h-[44px]"
                        type="submit"
                      >
                        {loading ? "loading..." : "Submit"}
                      </button>
                    </div>
                    <div
                      className="mt-4 mb-[32px] flex justify-center gap-1 w-full"
                      //   onClick={() => navigate("/forgotpassword")}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="mb-0 text-sm">
                        Remember your details?{" "}
                        <Link to={"/admin/login"} className="text-[#A74225]">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </AdminAuthLayout>
  );
};

export default AdminForgotPassword;
